import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
    ActivatedRoute,
    Router,
    RouterLink,
    RouterModule,
    RouterOutlet,
} from '@angular/router';
import { UserComponent } from 'app/layout/common/user/user.component';
import { LoginService } from 'app/login.service';
import { Utilisateur } from 'app/models/Utilisateur';
import { SharedModule } from 'app/shared/shared.module';
import { ActifSearchComponent } from '../../../../modules/actif-search/actif-search.component';

@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('300ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
        ]),
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate(
                    '300ms ease-out',
                    style({ transform: 'translateX(0%)' })
                ),
            ]),
            transition(':leave', [
                animate(
                    '300ms ease-in',
                    style({ transform: 'translateX(-100%)' })
                ),
            ]),
        ]),
    ],
    imports: [
        NgOptimizedImage,
        RouterLink,
        MatIconModule,
        UserComponent,
        RouterOutlet,
        RouterModule,
        CommonModule,
        SharedModule,
        ActifSearchComponent,
    ],
})
export class ModernLayoutComponent implements OnInit {
    navigationItems = [
        { title: 'Actualités', link: '/actualites' },
        { title: 'Actifs', link: '/actifs' },
        { title: 'Émissions', link: '/emission' },
        { title: 'Graphique', link: '/graphique/TUNINDEX' },
    ];
    username: string;
    isLogged: boolean;
    utilisateur: Utilisateur;
    idUtilisateurConnecte: string;
    isScreenSmall: boolean;
    showSidebar: boolean = false;
    showAd: boolean = this.route.snapshot.data['showAd'];

    constructor(
        private loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.isLogged = this.loginService.getIsLogged();
        this.idUtilisateurConnecte = this.utilisateur?.userId;
    }

    isCurrentPath(link: string) {
        return (
            (link === '/actualites' && this.router.url === '/') ||
            this.router.url.startsWith(link)
        );
    }
}
