<div class="absolute inset-0">
    <mat-drawer-container class="h-full flex-auto">
        <!-- Drawer -->
        <mat-drawer
            class="w-90 border-r-inherit dark:bg-gray-900"
            [autoFocus]="false"
            [mode]="drawerMode"
            [opened]="drawerOpened"
            #matDrawer
        >
            <div class="flex flex-col items-start border-b p-8">
                <!-- Retour -->
                <a
                    class="inline-flex items-center leading-6 text-primary hover:underline"
                    (click)="retour()"
                >
                    <span class="inline-flex cursor-pointer items-center">
                        <mat-icon
                            class="text-current icon-size-5"
                            [svgIcon]="'heroicons_solid:arrow-small-left'"
                        />
                        <span class="ml-1.5 font-medium leading-5">Retour</span>
                    </span>
                </a>
            </div>

            <!-- Article Steps -->
            <div class="px-8 py-2">
                <ol>
                    <ng-container
                        *ngFor="
                            let step of steps;
                            let last = last;
                            trackBy: trackByFn
                        "
                    >
                        <li
                            class="group relative py-6"
                            [class.current-step]="step.order === currentStep"
                        >
                            <ng-container *ngIf="!last">
                                <div
                                    class="absolute left-4 top-6 -ml-px h-full w-0.5"
                                    [ngClass]="{
                                        'bg-primary': step.order < currentStep,
                                        'bg-gray-300 dark:bg-gray-600':
                                            step.order >= currentStep,
                                    }"
                                ></div>
                            </ng-container>
                            <div
                                class="relative flex cursor-pointer items-start"
                                (click)="goToStep(step.order)"
                            >
                                <div
                                    class="flex h-8 w-8 flex-0 items-center justify-center rounded-full bg-white ring-2 ring-inset"
                                    [ngClass]="{
                                        'bg-[#4f46e5] text-on-primary ring-transparent group-hover:bg-primary-800':
                                            step.order < currentStep,
                                        'ring-primary':
                                            step.order === currentStep,
                                        'ring-gray-300 group-hover:ring-gray-400 dark:ring-gray-600':
                                            step.order > currentStep,
                                        'bg-white':
                                            step.order === currentStep ||
                                            step.order > currentStep,
                                    }"
                                >
                                    <!-- Check icon, show if the step is completed -->
                                    <ng-container
                                        *ngIf="step.order < currentStep"
                                    >
                                        <mat-icon
                                            class="text-current icon-size-5"
                                            [svgIcon]="'heroicons_solid:check'"
                                        ></mat-icon>
                                    </ng-container>
                                    <!-- Step order, show if the step is the current step -->
                                    <ng-container
                                        *ngIf="step.order === currentStep"
                                    >
                                        <div
                                            class="text-md font-semibold text-primary dark:text-primary-500"
                                        >
                                            {{ step.order + 1 }}
                                        </div>
                                    </ng-container>
                                    <!-- Step order, show if the step is not completed -->
                                    <ng-container
                                        *ngIf="step.order > currentStep"
                                    >
                                        <div
                                            class="text-hint text-md font-semibold group-hover:text-secondary"
                                        >
                                            {{ step.order + 1 }}
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="ml-4">
                                    <div class="font-medium leading-4">
                                        {{ step.title }}
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ol>
            </div>
        </mat-drawer>

        <!-- Drawer content -->
        <mat-drawer-content class="flex flex-col">
            <!-- Header -->
            <div
                class="bg-card flex flex-1 items-center border-b py-2 pl-4 pr-6 dark:bg-transparent sm:py-4 md:pl-6 md:pr-8 lg:hidden lg:border-b-0"
            >
                <!-- Title & Actions -->
                <a mat-icon-button [routerLink]="['..']">
                    <mat-icon
                        [svgIcon]="'heroicons_solid:arrow-small-left'"
                    ></mat-icon>
                </a>
                <h2
                    class="ml-2.5 truncate text-md font-medium tracking-tight sm:text-xl"
                >
                    {{ steps[currentStep]?.title }}
                </h2>
            </div>
            <mat-progress-bar
                class="hidden h-0.5 w-full flex-0 text-[#4f46e5] lg:block"
                [value]="(100 * (currentStep + 1)) / totalSteps"
            />

            <!-- Main -->
            <div class="flex-auto overflow-y-auto" cdkScrollable>
                <!-- Steps -->
                <mat-tab-group
                    class="fuse-mat-no-header"
                    [animationDuration]="'200'"
                    #courseSteps
                >
                    <ng-container>
                        <mat-tab>
                            <ng-template matTabContent>
                                <form [formGroup]="articleForm">
                                    <div
                                        class="bg-card prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4"
                                        *ngIf="steps[currentStep]?.order === 0"
                                    >
                                        <h2
                                            class="ml-2.5 truncate text-md font-medium tracking-tight underline sm:text-xl"
                                        >
                                            {{ steps[currentStep]?.title }}
                                        </h2>
                                        <!-- Step 0: Choose between Article or News -->
                                        <mat-radio-group
                                            [formControlName]="'type'"
                                            id="type"
                                            class="flex flex-col"
                                            [color]="'primary'"
                                        >
                                            <div
                                                class="mt-6 grid flex-auto grid-cols-4 gap-4"
                                            >
                                                <!-- Article -->
                                                <div
                                                    class="col-span-2 flex flex-col items-center justify-center rounded-2xl bg-indigo-50 px-1 py-8 text-indigo-800 dark:bg-white dark:bg-opacity-5 dark:text-indigo-400"
                                                >
                                                    <mat-radio-button
                                                        class="mb-2"
                                                        [value]="'article'"
                                                    >
                                                        <div
                                                            class="text-xl font-semibold leading-none tracking-tight sm:text-xl"
                                                        >
                                                            Article
                                                        </div>
                                                    </mat-radio-button>
                                                </div>
                                                <!-- News-->
                                                <div
                                                    class="col-span-2 flex flex-col items-center justify-center rounded-2xl bg-indigo-50 px-1 py-8 text-indigo-800 dark:bg-white dark:bg-opacity-5 dark:text-indigo-400"
                                                >
                                                    <mat-radio-button
                                                        class="mb-2"
                                                        [value]="'news'"
                                                    >
                                                        <div
                                                            class="text-xl font-semibold leading-none tracking-tight sm:text-xl"
                                                        >
                                                            News
                                                        </div>
                                                    </mat-radio-button>
                                                </div>
                                            </div>
                                        </mat-radio-group>
                                        <!-- Image de l'article-->
                                        <h2
                                            class="ml-2.5 truncate text-md font-medium tracking-tight underline sm:text-xl"
                                        >
                                            Image de l'article
                                        </h2>
                                        <button
                                            class="mr-1 px-3"
                                            mat-button
                                            (click)="isDisabled = !isDisabled"
                                            (click)="openInput()"
                                        >
                                            <mat-icon
                                                class="mr-2 icon-size-5"
                                                [svgIcon]="
                                                    'heroicons_solid:photo'
                                                "
                                            ></mat-icon>
                                            {{
                                                selectedFiles
                                                    ? (selectedFiles.item(0)
                                                          .name | slice: 0 : 10)
                                                    : 'Photo'
                                            }}
                                        </button>
                                        <input
                                            id="fileInput"
                                            hidden
                                            type="file"
                                            (change)="selectFile($event)"
                                            name="file"
                                            accept="image/*"
                                        />
                                    </div>
                                    <div
                                        class="bg-card prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4"
                                        *ngIf="steps[currentStep]?.order === 1"
                                    >
                                        <h2
                                            class="ml-2.5 truncate text-md font-medium tracking-tight underline sm:text-xl"
                                        >
                                            {{ steps[currentStep]?.title }}
                                        </h2>
                                        <mat-form-field class="w-full">
                                            <mat-label
                                                >Nom de l'article</mat-label
                                            >
                                            <input
                                                id="nom"
                                                matInput
                                                [formControlName]="'nom'"
                                            />
                                        </mat-form-field>
                                        <quill-editor
                                            [styles]="{ minHeight: '450px' }"
                                            class="mt-2 stroke-[#64748b]"
                                            id="contenu"
                                            formControlName="contenu"
                                            bounds="self"
                                        />
                                    </div>
                                    <div
                                        class="bg-card prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4"
                                        *ngIf="steps[currentStep]?.order === 2"
                                    >
                                        <h2
                                            class="ml-2.5 truncate text-md font-medium tracking-tight underline sm:text-xl"
                                        >
                                            {{ steps[currentStep]?.title }}
                                        </h2>
                                        <mat-form-field class="w-full">
                                            <mat-label
                                                class="text-lg font-medium"
                                                >Description de
                                                l'article</mat-label
                                            >
                                            <textarea
                                                id="description"
                                                #message
                                                matInput
                                                [formControlName]="
                                                    'description'
                                                "
                                                maxlength="200"
                                                placeholder="Ex. 100 Main St"
                                            >
1600 Amphitheatre Pkwy</textarea
                                            >
                                            <mat-hint class="content-end"
                                                >{{ message.value.length }} /
                                                200</mat-hint
                                            >
                                        </mat-form-field>
                                        <div
                                            class="gt-sm:mt-0 gt-sm:ml-16 mt-8 flex flex-col"
                                        >
                                            <mat-label
                                                class="m-2 text-lg font-medium"
                                                >Théme de l'article</mat-label
                                            >
                                            <div class="flex flex-col">
                                                <ng-container
                                                    *ngIf="
                                                        articleForm.get('type')
                                                            .value === 'article'
                                                    "
                                                >
                                                    <mat-radio-group
                                                        class="mt-2 flex max-h-80 snap-y flex-col overflow-auto"
                                                        [formControlName]="
                                                            'themeArticle'
                                                        "
                                                    >
                                                        <mat-radio-button
                                                            [value]="
                                                                'ANALYSE_BOURSIERE_BVMT'
                                                            "
                                                            >Analyse boursière
                                                            (BVMT)</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'ANALYSE_BOURSIERE_INTER'
                                                            "
                                                            >Analyse boursière
                                                            (INTER)</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'ANALYSE_MACRO_ECONOMIQUE'
                                                            "
                                                            >Analyse
                                                            macro-économique</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'ARTICLE_EDUCATIF_DEBUTANT'
                                                            "
                                                            >Article éducatif
                                                            Débutant</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'ARTICLE_EDUCATIF_AVANCE'
                                                            "
                                                            >Article éducatif
                                                            avancé</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'NOUVELLES_DIRIGEANTS'
                                                            "
                                                            >Nouvelles
                                                            dirigeants</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'ACTUALITES_ECONOMIQUES_TUNISIE'
                                                            "
                                                            >Actualités
                                                            économiques
                                                            Tunisie</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'ACTUALITES_ECONOMIQUES_INTERNATIONALES '
                                                            "
                                                            >Actualités
                                                            économiques
                                                            internationales</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'CRYPTOMONNAIES_BLOCKCHAIN'
                                                            "
                                                            >Cryptomonnaies &
                                                            Blockchain</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'FINANCE_DURABLE_ESG '
                                                            "
                                                            >Finance durable &
                                                            ESG</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'ANALYSE_TECHNIQUE '
                                                            "
                                                            >Analyse
                                                            technique</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'FOCUS_INVESTISSEUR '
                                                            "
                                                            >Focus
                                                            investisseur</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'INFOGRAPHIE'
                                                            "
                                                            >Infographie</mat-radio-button
                                                        >
                                                    </mat-radio-group>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        articleForm.get('type')
                                                            .value === 'news'
                                                    "
                                                >
                                                    <mat-radio-group
                                                        class="mt-2 flex max-h-80 snap-y flex-col overflow-auto"
                                                        [formControlName]="
                                                            'themeNews'
                                                        "
                                                    >
                                                        <mat-radio-button
                                                            [value]="'NEWS'"
                                                            >News</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'INFORMATION_FINANCIERE'
                                                            "
                                                            >Information
                                                            financiére</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'COMMUNIQUE_DE_PRESSE'
                                                            "
                                                            >Communiqué de
                                                            presse</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'NEWS_ECONOMIQUE_NATIONALE'
                                                            "
                                                            >News économique
                                                            nationale</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                'NEWS_ECONOMIQUE_INTERNATIONALE'
                                                            "
                                                            >News économique
                                                            internationale</mat-radio-button
                                                        >
                                                        <mat-radio-button
                                                            [value]="
                                                                ' NEWS_DES_SOCIETES_COTEES'
                                                            "
                                                            >News des sociétés
                                                            cotées</mat-radio-button
                                                        >
                                                        <!-- Add more themes as needed -->
                                                    </mat-radio-group>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="bg-card prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4"
                                        *ngIf="steps[currentStep]?.order === 3"
                                    >
                                        <h2
                                            class="ml-2.5 truncate text-md font-medium tracking-tight underline sm:text-xl"
                                        >
                                            {{ steps[currentStep]?.title }}
                                        </h2>

                                        <mat-radio-group
                                            id="actif"
                                            class="mt-2 flex max-h-80 snap-y flex-col overflow-auto"
                                            [color]="'primary'"
                                            [formControlName]="'actif'"
                                            [value]="'actif'"
                                        >
                                            <mat-radio-button
                                                class="mb-2 mt-1 flex flex-col text-base leading-none tracking-tight"
                                                *ngFor="let actif of actifs"
                                                [value]="actif.referentiel.isin"
                                            >
                                                {{
                                                    actif.referentiel.stockName
                                                }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <button
                                            class="mt-3 rounded-full px-4 py-2 text-sm font-semibold text-white shadow-sm"
                                            mat-flat-button
                                            [color]="'primary'"
                                            [disabled]="
                                                !articleForm.valid ||
                                                !isButtonEnabled
                                            "
                                            (click)="soumettre()"
                                        >
                                            Envoyer votre article
                                        </button>
                                        <fuse-alert
                                            [type]="'warn'"
                                            [dismissible]="true"
                                            [dismissed]="false"
                                            [appearance]="'border'"
                                            *ngIf="
                                                articleNonSauvegarde === true
                                            "
                                        >
                                            <span fuseAlertTitle>Erreur</span>
                                            Un erreur s'est produit, veuillez
                                            réessayer plutard
                                        </fuse-alert>
                                    </div>
                                </form>
                                <div
                                    class="bg-card prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4"
                                    *ngIf="steps[currentStep]?.order === 4"
                                >
                                    <div *ngIf="articleSauvegarde === true">
                                        <h2
                                            class="ml-2.5 truncate text-md font-medium tracking-tight sm:text-xl"
                                        >
                                            {{ steps[currentStep]?.title }}
                                        </h2>
                                        <div
                                            class="mt-8 grid w-full grid-cols-1 gap-8"
                                        >
                                            <div
                                                class="grid gap-8 sm:grid-flow-col"
                                            >
                                                <div
                                                    class="bg-card relative flex flex-auto flex-col overflow-hidden p-6 pb-3 pr-3"
                                                >
                                                    <fuse-card
                                                        class="flex w-full max-w-80 flex-col items-center justify-between p-8 md:max-w-160 md:flex-row"
                                                    >
                                                        <div
                                                            class="flex flex-auto flex-col justify-center"
                                                        >
                                                            <div
                                                                class="text-lg leading-relaxed"
                                                            >
                                                                Félicitations,
                                                                votre article a
                                                                été bien
                                                                enregistré, nous
                                                                reviendrons vers
                                                                vous par mail
                                                                pour confirmer
                                                                sa publication.
                                                            </div>
                                                        </div>
                                                    </fuse-card>
                                                </div>
                                                <div
                                                    class="bg-card relative flex flex-auto flex-col overflow-hidden p-6 pb-3 pr-3"
                                                >
                                                    <img
                                                        class="h-full w-full"
                                                        src="assets/irbe7/validationArticle.webp"
                                                        alt="Card cover image"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="articleSauvegarde === false">
                                        <div
                                            class="mt-8 grid w-full grid-cols-1 gap-8"
                                        >
                                            <div
                                                class="grid gap-8 sm:grid-flow-col"
                                            >
                                                <div
                                                    class="bg-card relative flex flex-auto flex-col overflow-hidden p-6 pb-3 pr-3"
                                                >
                                                    <fuse-card
                                                        class="flex w-full max-w-80 flex-col items-center justify-between p-8 md:max-w-160 md:flex-row"
                                                    >
                                                        <div
                                                            class="flex flex-auto flex-col justify-center"
                                                        >
                                                            <div
                                                                class="text-2xl leading-relaxed"
                                                            >
                                                                Veuillez
                                                                complétez votre
                                                                article d'abord
                                                            </div>
                                                        </div>
                                                    </fuse-card>
                                                </div>
                                                <div
                                                    class="bg-card relative flex flex-auto flex-col overflow-hidden p-6 pb-3 pr-3"
                                                >
                                                    <img
                                                        class="h-full w-full"
                                                        src="assets/irbe7/EchecArticle.webp"
                                                        alt="Card cover image"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>

                <!-- Navigation - Desktop -->
                <div class="sticky bottom-1 z-10 hidden p-1 lg:flex">
                    <div
                        class="mx-auto flex items-center justify-center rounded-full bg-primary p-2 shadow-lg"
                    >
                        <button
                            class="flex-0 text-white"
                            mat-flat-button
                            (click)="goToPreviousStep()"
                        >
                            <mat-icon
                                class="mr-2"
                                [svgIcon]="'heroicons_solid:arrow-small-left'"
                            ></mat-icon>
                            <span class="mr-1">Prev</span>
                        </button>
                        <div
                            class="mx-2.5 flex items-center justify-center font-medium leading-5 text-on-primary"
                        >
                            <span>{{ currentStep + 1 }}</span>
                            <span class="text-hint mx-0.5">/</span>
                            <span>{{ totalSteps }}</span>
                        </div>
                        <button
                            class="flex-0 text-white"
                            mat-flat-button
                            (click)="goToNextStep()"
                        >
                            <span class="ml-1">Next</span>
                            <mat-icon
                                class="ml-2"
                                [svgIcon]="'heroicons_solid:arrow-small-right'"
                            ></mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Progress & Navigation - Mobile -->
            <div class="bg-card flex items-center border-t p-4 lg:hidden">
                <button mat-icon-button (click)="matDrawer.toggle()">
                    <mat-icon
                        [svgIcon]="'heroicons_outline:view-list'"
                    ></mat-icon>
                </button>
                <div
                    class="ml-1 flex items-center justify-center font-medium leading-5 lg:ml-2"
                >
                    <span>{{ currentStep + 1 }}</span>
                    <span class="text-hint mx-0.5">/</span>
                    <span>{{ totalSteps }}</span>
                </div>
                <mat-progress-bar
                    class="ml-6 flex-auto rounded-full text-[#4f46e5]"
                    [value]="(100 * (currentStep + 1)) / totalSteps"
                />
                <button
                    class="ml-4"
                    mat-icon-button
                    (click)="goToPreviousStep()"
                >
                    <mat-icon
                        [svgIcon]="'heroicons_solid:arrow-small-left'"
                    ></mat-icon>
                </button>
                <button class="ml-0.5" mat-icon-button (click)="goToNextStep()">
                    <mat-icon
                        [svgIcon]="'heroicons_solid:arrow-small-right'"
                    ></mat-icon>
                </button>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
