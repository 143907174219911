import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
    Component,
    Inject,
    LOCALE_ID,
    PLATFORM_ID,
    TransferState,
    inject,
} from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterOutlet,
} from '@angular/router';
import { environment } from 'environments/environment';
import { OneSignal } from 'onesignal-ngx';
import { MetaService } from './shared/meta.service';
import { SharedModule } from './shared/shared.module';

declare const gtag: Function;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
    standalone: true,
    imports: [RouterOutlet, SharedModule],
})
export class AppComponent {
    transferState = inject(TransferState);
    isNavigating: boolean = true;

    constructor(
        private router: Router,
        private metaService: MetaService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        if (isPlatformBrowser(this.platformId))
            inject(OneSignal).init({
                appId: environment.onesignal_appId,
            });

        registerLocaleData(localeFr, 'fr');

        this.metaService.updateMetaTags({
            title: 'Irbe7',
            description: 'Ressources et outils pour investisseurs intelligents',
            ogTitle: 'Irbe7',
            ogType: 'website',
            ogUrl: environment.apiUrl ,
            ogDescription:
                'Ressources et outils pour investisseurs intelligents',
            ogImage: 'https://d3b156xx8s447.cloudfront.net/Logo/LogoIrbe7',
            canonicalLink: environment.apiUrl ,
            twitterTitle: 'Irbe7',
            twitterDescription:
                'Ressources et outils pour investisseurs intelligents',
            twitterImage: 'https://d3b156xx8s447.cloudfront.net/Logo/LogoIrbe7',
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.isNavigating = true;
            } else if (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError
            ) {
                this.isNavigating = false;
            }
        });
    }
}
