<div
    *ngIf="showSidebar"
    [@fadeInOut]
    (click)="showSidebar = false"
    class="fixed inset-0 z-50 bg-black/25 md:hidden"
></div>
<div
    *ngIf="showSidebar"
    [@slideInOut]
    class="fixed inset-y-0 left-0 z-50 flex w-64 flex-col gap-1 bg-[#f5f5f5] px-3 text-lg font-semibold uppercase text-[#707070] md:hidden"
>
    <img
        alt="logo irbe7"
        class="my-6 h-9 w-30"
        ngSrc="/assets/irbe7/LogoIrbe7-01.webp"
        height="36"
        width="120"
    />
    <a
        *ngFor="let item of navigationItems"
        class="rounded-lg p-1.5"
        [ngClass]="isCurrentPath(item.link) ? 'bg-[#c9d5f2] text-[#0045E6]' : 'text-black'"
        [routerLink]="item.link"
        (click)="showSidebar = false"
    >
        {{ item.title }}
    </a>
</div>
<div class="flex flex-col">
    <div class="flex min-h-screen flex-col">
        <div *ngIf="showAd" class="w-full bg-white p-5">
            <div
                class="mx-auto flex h-30 w-full max-w-6xl items-center justify-center  md:h-60 md:text-xl"
            >
            <a
            class=""
            [routerLink]="['/emission/la-premiere-annee-de-lentreprise-sest-soldee-par-une-perte-de-60%25/MlkDQ3fM5ZI']"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                alt="navbar image"
                class=" h-full w-full object-cover "
                ngSrc="/assets/irbe7/navbar-image.webp"
                height="45"
                width="150"
            />
        </a>
            </div>
        </div>
        <div class="sticky top-0 z-40 bg-[#FAFAFA] shadow">
            <div
                class="flex h-16 items-center justify-between gap-4 p-3 md:px-10"
            >
                <ng-container *ngIf="isLogged !== undefined">
                    <button class="md:hidden" (click)="showSidebar = true">
                        <mat-icon [svgIcon]="'heroicons_outline:bars-3'" />
                    </button>
                    <div class="flex items-center gap-4">
                        <a routerLink="/actualites">
                            <img
                                alt="logo irbe7"
                                class="h-9 w-30"
                                ngSrc="/assets/irbe7/LogoIrbe7-01.webp"
                                height="36"
                                width="120"
                            />
                        </a>
                        <a
                            *ngFor="let item of navigationItems"
                            class="hidden rounded-lg p-1.5 text-lg font-semibold md:block"
                            [ngClass]="
                                isCurrentPath(item.link)
                                    ? 'bg-[#c9d5f2] text-[#0045E6]'
                                    : 'text-black'
                            "
                            [routerLink]="item.link"
                        >
                            {{ item.title }}
                        </a>
                    </div>
                    <div class="flex items-center gap-4">
                        <app-actif-search class="hidden md:block" />
                        <user />
                    </div>
                </ng-container>
            </div>

            <div class="bg-[#f5f5f5] p-3 md:hidden">
                <app-actif-search />
            </div>
        </div>
        <div class="relative flex-1">
            <router-outlet />
        </div>
    </div>
    <div
        class="flex flex-col-reverse justify-between gap-4 bg-[#d9d9d9] p-4 text-[#545454] md:flex-row lg:items-center lg:gap-10 lg:px-10 lg:text-lg lg:uppercase"
    >
        <div
            class="flex flex-col md:basis-1/2 lg:basis-auto lg:flex-row lg:items-center lg:gap-4"
        >
            <span class="font-bold lg:hidden">A propos</span>
            <a routerLink="help-center/publicite">Publicité</a>
            <a routerLink="help-center/qui_sommes_nous">Qui sommes nous?</a>
            <a routerLink="help-center/politique_confidentialite"
                >Politique de confidentialité</a
            >
            <a routerLink="help-center/cookies">Politique de Cookies</a>
            <a routerLink="help-center/conditions_generales"
                >Conditions d'utilisation</a
            >
        </div>
        <div
            class="flex flex-col gap-2 md:basis-1/2 lg:basis-auto lg:flex-row lg:items-center lg:gap-4"
        >
            <span class="font-bold lg:font-medium">Pour nous suivre</span>
            <div class="flex items-center gap-2 lg:gap-4">
                <a
                    href="https://www.facebook.com/irbe7bourse"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="facebook_transparent.png"
                        class="icon-size-5"
                        ngSrc="/assets/images/logo/facebook_transparent.png"
                        height="20"
                        width="20"
                    />
                </a>
                <a
                    href="https://www.linkedin.com/company/irbe7"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="linkedin_transparent.png"
                        class="icon-size-5"
                        ngSrc="/assets/images/logo/linkedin_transparent.png"
                        height="20"
                        width="20"
                    />
                </a>
            </div>
        </div>
    </div>
    <span
        class="w-full bg-[#0045E6] py-4 text-center text-sm font-light text-white sm:text-lg lg:uppercase"
        >Copyright © 2024 Irbe7. Tous droits réservés.</span
    >
</div>
