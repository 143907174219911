import { Route } from '@angular/router';
import { AppGuard } from './app.guard';
import { ModernLayoutComponent } from './layout/layouts/horizontal/modern/modern.component';
import { CreationArticleComponent } from './modules/media/creation-article/creation-article.component';
import { MediaComponent } from './modules/media/media.component';
import { mediaResolver } from './modules/media/media.resolver';
import { DirtyCheckGuard } from './shared/dirty-check.guard';
import { PrincipauxResolver } from './shared/principaux.resolver';

export const appRoutes: Route[] = [
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '' },
    {
        path: 'graphique/:actif',
        data: {
            showAd: false,
        },
        component: ModernLayoutComponent,
        loadChildren: () => import('app/modules/tools/tools.routes'),
    },
    {
        path: 'actualites/creation-article',
        data: {
            showAd: false,
            requiredRoles: ['admin', 'user'],
        },
        canActivate: [AppGuard],
        canDeactivate: [DirtyCheckGuard],
        component: ModernLayoutComponent,
        children: [
            {
                path: '',
                component: CreationArticleComponent,
            },
        ],
    },
    {
        path: '',
        data: {
            showAd: true,
        },
        component: ModernLayoutComponent,
        children: [
            {
                path: '',
                resolve: {
                    media: mediaResolver,
                    principaux: PrincipauxResolver,
                },
                component: MediaComponent,
            },
            {
                path: 'actifs',
                loadChildren: () => import('app/modules/actifs/actifs.routes'),
            },
            {
                path: 'inviter',
                loadChildren: () =>
                    import('app/modules/inviter/inviter.routes'),
            },
            {
                path: 'actualites',
                loadChildren: () => import('app/modules/media/media.routes'),
            },
            {
                path: 'mur-utilisateur-autre',
                loadChildren: () =>
                    import(
                        'app/modules/mur-utilisateur/consultation-mur-utilisateur-autre/consultation-mur-utilisateur-autre.routes'
                    ),
            },
            {
                path: 'mur-utilisateur-connecte',
                loadChildren: () =>
                    import(
                        'app/modules/mur-utilisateur/consultation-mur-utilisateur-connecte/consultation-mur-utilisateur-connecte.routes'
                    ),
                canActivate: [AppGuard],
                data: { requiredRoles: ['admin', 'user'] },
            },
            {
                path: 'parametre',
                loadChildren: () =>
                    import(
                        'app/modules/parametre-profil/parametre-profil.routes'
                    ),
                canActivate: [AppGuard],
                data: { requiredRoles: ['admin', 'user'] },
            },
            {
                path: 'liste-suivi',
                loadChildren: () =>
                    import(
                        'app/modules/mur-utilisateur/liste-suivi/liste-suivi.routes'
                    ),
            },
            {
                path: 'liste-abonnee',
                loadChildren: () =>
                    import(
                        'app/modules/mur-utilisateur/liste-abonnee/liste-abonnee.routes'
                    ),
            },
            {
                path: 'help-center',
                loadChildren: () =>
                    import('app/modules/help-center/help-center.routes'),
            },
            {
                path: 'emission',
                loadChildren: () =>
                    import('app/modules/emission/emission.routes'),
            },
        ],
    },
];
