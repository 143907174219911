import {
    CommonModule,
    isPlatformBrowser,
    NgOptimizedImage,
} from '@angular/common';
import {
    Component,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Article } from 'app/models/Article';
import { ExtractTextFromHtmlPipe } from 'app/shared/extract-text-from-html.pipe';
import { environment } from 'environments/environment';
import {
    CarouselComponent,
    CarouselModule,
    OwlOptions,
} from 'ngx-owl-carousel-o';
import { ConvertToSlugPipe } from 'app/shared/convert-to-slug.pipe';

@Component({
    selector: 'app-infographique-carousel',
    standalone: true,
    imports: [
        NgOptimizedImage,
        MatIconModule,
        CarouselModule,
        CommonModule,
        ExtractTextFromHtmlPipe,
        RouterModule,
        ConvertToSlugPipe,

    ],
    templateUrl: './infographique-carousel.component.html',
})
export class InfographiqueCarouselComponent implements OnInit {
    @Input() listeArticlesInfographie: Article[];
    @ViewChild('carousel', { static: false }) carousel!: CarouselComponent;
    cloud_front = environment.cloud_front_host;
    isBrowser: boolean = false;

    options: OwlOptions = {
        loop: true,
        mouseDrag: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            640: {
                items: 2,
            },
            1024: {
                items: 3,
            },
            1280: {
                items: 4,
            },
            1536: {
                items: 5,
            },
        },
    };

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    ngOnInit(): void {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    goToNextSlide() {
        this.carousel.next();
    }

    goToPreviousSlide() {
        this.carousel.prev();
    }
}
