<div class="w-full bg-white">
    <div class="mx-auto flex w-full max-w-7xl flex-col gap-4 bg-white py-8">
        <div
            class="relative mx-4 md:hidden"
            [ngClass]="{
                'before:absolute before:inset-y-0 before:left-0 before:w-10 before:bg-gradient-to-r before:from-black/15 before:to-transparent':
                    showStartGradient,
                'after:absolute after:inset-y-0 after:right-0 after:w-10 after:bg-gradient-to-r after:from-transparent after:to-black/15':
                    showEndGradient,
            }"
        >
            <div
                #scrollContainer
                class="flex justify-between gap-8 overflow-x-scroll"
                (scroll)="checkScroll()"
            >
                <a routerLink="actifs/TUNINDEX/TN0009050014">
                    <app-index-card
                        [last]="tunindex?.last"
                        [change]="tunindex?.change"
                        stockName="TUNINDEX"
                    />
                </a>
                <a routerLink="actifs/TUNINDEX20/TN0009050287">
                    <app-index-card
                        [last]="tunindex20?.last"
                        [change]="tunindex20?.change"
                        stockName="TUNINDEX20"
                    />
                </a>
                <a
                    [routerLink]="[
                        '/actifs',
                        hausses[0]?.referentiel?.stockName,
                        hausses[0]?.referentiel?.isin,
                    ]"
                >
                    <app-index-card
                        [last]="hausses[0].volume"
                        [change]="hausses[0].change"
                        [stockName]="hausses[0].referentiel.ticker"
                    />
                </a>
                <a
                    [routerLink]="[
                        '/actifs',
                        hausses[1]?.referentiel?.stockName,
                        hausses[1]?.referentiel?.isin,
                    ]"
                >
                    <app-index-card
                        [last]="hausses[1].volume"
                        [change]="hausses[1].change"
                        [stockName]="hausses[1].referentiel.ticker"
                    />
                </a>
                <a
                    [routerLink]="[
                        '/actifs',
                        baisses[0]?.referentiel?.stockName,
                        baisses[0]?.referentiel?.isin,
                    ]"
                >
                    <app-index-card
                        [last]="baisses[0].volume"
                        [change]="baisses[0].change"
                        [stockName]="baisses[0].referentiel.ticker"
                    />
                </a>
                <a
                    [routerLink]="[
                        '/actifs',
                        baisses[1]?.referentiel?.stockName,
                        baisses[1]?.referentiel?.isin,
                    ]"
                >
                    <app-index-card
                        [last]="baisses[1].volume"
                        [change]="baisses[1].change"
                        [stockName]="baisses[1].referentiel.ticker"
                    />
                </a>
            </div>
        </div>
        <div class="hidden h-56 w-full gap-4 px-4 md:flex">
            <div class="flex basis-2/5">
                <div class="flex flex-col justify-evenly">
                    <button
                        class="flex items-center"
                        (click)="graphToShow = 'TUNINDEX'"
                    >
                        <app-index-card
                            class="w-20"
                            [last]="tunindex?.last"
                            [change]="tunindex?.change"
                            stockName="TUNINDEX"
                        />
                        <mat-icon
                            *ngIf="graphToShow === 'TUNINDEX'"
                            class="text-[#7a7a7a] icon-size-5"
                            svgIcon="feather:chevron-right"
                        />
                    </button>
                    <button
                        class="flex items-center"
                        (click)="graphToShow = 'TUNINDEX20'"
                    >
                        <app-index-card
                            class="w-20"
                            [last]="tunindex20?.last"
                            [change]="tunindex20?.change"
                            stockName="TUNINDEX20"
                        />
                        <mat-icon
                            *ngIf="graphToShow === 'TUNINDEX20'"
                            class="text-[#7a7a7a] icon-size-5"
                            svgIcon="feather:chevron-right"
                        />
                    </button>
                </div>
                <div class="flex w-full flex-col gap-2">
                    <div class="flex items-center gap-2 self-start">
                        <span
                            class="text-[#E70013]"
                            *ngIf="marketStatus === 'Fermé'; else openStatus"
                        >
                            Fermé
                        </span>
                        <ng-template #openStatus>
                            <span class="text-[#00A859]">Ouvert</span>
                        </ng-template>

                        <span class="truncate text-ellipsis text-nowrap">
                            Séance du {{ tunindex?.seance }}
                            {{ tunindex?.time.split(':')[0] }}:{{
                                tunindex?.time.split(':')[1]
                            }}
                        </span>
                    </div>
                    <div class="flex items-center gap-6 self-end">
                        <a
                            class="cursor-pointer self-end"
                            [href]="'/graphique/' + graphToShow"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                alt="bougie.png"
                                class="icon-size-3"
                                ngSrc="/assets/images/logo/bougie.png"
                                width="16"
                                height="16"
                            />
                        </a>
                    </div>
                    <div #chartContainer class="relative flex-1">
                        <div
                            #tooltipContainer
                            class="absolute z-10 text-nowrap rounded border border-[#ccc] bg-white p-2 text-sm"
                            [ngClass]="showTooltip ? 'block' : 'hidden'"
                        >
                            <div class="text-center">
                                {{ tooltipValue | number: '.2-2' }}
                            </div>
                            <div>
                                {{ tooltipDate | date: 'dd MMM YYYY' }}
                            </div>
                        </div>
                    </div>
                    <span class="text-xs text-gray-600"
                        >Propulsé par
                        <a
                            class="hover:underline"
                            href="https://www.tradingview.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Tradingview</a
                        ></span
                    >
                </div>
            </div>

            <div class="border-r"></div>

            <div class="flex basis-1/5 flex-col justify-between gap-2">
                <div class="flex flex-col gap-4">
                    <span class="text-center font-semibold uppercase"
                        >Plus gros volumes</span
                    >
                    <table>
                        <thead>
                            <tr class="text-sm [&>*]:p-1">
                                <th class="border-r text-start">Valeur</th>
                                <th class="text-nowrap border-r" colspan="2">
                                    Volume (DT)
                                </th>
                                <th>%Variation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="border-t text-sm [&>*]:p-1"
                                *ngFor="let volume of volumes | slice: 0 : 3"
                            >
                                <td class="border-r">
                                    <a
                                        class="hover:underline"
                                        [routerLink]="[
                                            '/actifs',
                                            volume?.referentiel?.stockName,
                                            volume?.referentiel?.isin,
                                        ]"
                                    >
                                        {{ volume?.referentiel?.ticker }}
                                    </a>
                                </td>
                                <td
                                    class="border-r text-center font-semibold"
                                    colspan="2"
                                >
                                    {{ volume?.volume | number: '.0-0' }}
                                </td>
                                <td
                                    class="text-center"
                                    [ngClass]="{
                                        'text-[#0E9687]': volume?.change >= 0,
                                        'text-[#E70013]': volume?.change < 0,
                                    }"
                                >
                                    <mat-icon
                                        class="icon-size-3"
                                        [ngClass]="{
                                            'text-[#0E9687]':
                                                volume?.change >= 0,
                                            'text-[#E70013]':
                                                volume?.change < 0,
                                        }"
                                        [svgIcon]="
                                            volume?.change >= 0
                                                ? 'heroicons_solid:arrow-small-up'
                                                : 'heroicons_solid:arrow-small-down'
                                        "
                                    />
                                    <span>{{
                                        volume?.change | abs | number: '.2-2'
                                    }}</span>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="text-sm">
                                <td
                                    class="text-nowrap pt-1 font-bold"
                                    colspan="2"
                                >
                                    Volume marché (DT)
                                </td>
                                <td class="pt-1 font-bold" colspan="2">
                                    {{ volumeTotal | number: '.0-0' }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <!-- TODO: Ajouter des params de filtrage pour la page et modifier le lien de cette bouton -->
                <button
                    (click)="scrollToDashboardAndSetTab(0)"
                    class="self-center rounded-full border border-[#0045E6] px-3 py-1 font-semibold text-[#0045E6]"
                >
                    Volume
                </button>
            </div>

            <div class="border-r"></div>

            <div class="flex basis-1/5 flex-col justify-between gap-2">
                <div class="flex flex-col gap-4">
                    <div class="flex w-full justify-center gap-4">
                        <button
                            class="font-semibold uppercase"
                            [ngClass]="{
                                'text-[#9f9f9f]': showBaisses,
                            }"
                            (click)="showBaisses = false"
                        >
                            Top Hausses
                        </button>
                        <div class="border-r"></div>
                        <button
                            class="font-semibold uppercase"
                            [ngClass]="{
                                'text-[#9f9f9f]': !showBaisses,
                            }"
                            (click)="showBaisses = true"
                        >
                            Top Baisses
                        </button>
                    </div>
                    <table>
                        <thead>
                            <tr class="text-sm [&>*]:p-1">
                                <th class="border-r text-start">Valeur</th>
                                <th class="border-r">Cours</th>
                                <th>%Variation</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="showBaisses">
                            <tr
                                class="border-t text-sm [&>*]:p-1"
                                *ngFor="let baisse of baisses | slice: 0 : 3"
                            >
                                <td class="border-r">
                                    <a
                                        class="hover:underline"
                                        [routerLink]="[
                                            '/actifs',
                                            baisse?.referentiel?.stockName,
                                            baisse?.referentiel?.isin,
                                        ]"
                                    >
                                        {{ baisse?.referentiel?.ticker }}</a
                                    >
                                </td>
                                <td class="border-r text-center font-semibold">
                                    {{ baisse?.last | number: '.2-2' }}
                                </td>
                                <td
                                    class="text-center"
                                    [ngClass]="{
                                        'text-[#0E9687]': baisse?.change >= 0,
                                        'text-[#E70013]': baisse?.change < 0,
                                    }"
                                >
                                    <mat-icon
                                        class="icon-size-3"
                                        [ngClass]="{
                                            'text-[#0E9687]':
                                                baisse?.change >= 0,
                                            'text-[#E70013]':
                                                baisse?.change < 0,
                                        }"
                                        [svgIcon]="
                                            baisse?.change >= 0
                                                ? 'heroicons_solid:arrow-small-up'
                                                : 'heroicons_solid:arrow-small-down'
                                        "
                                    />
                                    <span>{{
                                        baisse?.change | abs | number: '.2-2'
                                    }}</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!showBaisses">
                            <tr
                                class="border-t text-sm [&>*]:p-1"
                                *ngFor="let hausse of hausses | slice: 0 : 3"
                            >
                                <td class="border-r">
                                    <a
                                        class="hover:underline"
                                        [routerLink]="[
                                            '/actifs',
                                            hausse?.referentiel?.stockName,
                                            hausse?.referentiel?.isin,
                                        ]"
                                    >
                                        {{ hausse?.referentiel?.ticker }}
                                    </a>
                                </td>
                                <td class="border-r text-center font-semibold">
                                    {{ hausse?.last | number: '.2-2' }}
                                </td>
                                <td
                                    class="text-center"
                                    [ngClass]="{
                                        'text-[#0E9687]': hausse?.change >= 0,
                                        'text-[#E70013]': hausse?.change < 0,
                                    }"
                                >
                                    <mat-icon
                                        class="icon-size-3"
                                        [ngClass]="{
                                            'text-[#0E9687]':
                                                hausse?.change >= 0,
                                            'text-[#E70013]':
                                                hausse?.change < 0,
                                        }"
                                        [svgIcon]="
                                            hausse?.change >= 0
                                                ? 'heroicons_solid:arrow-small-up'
                                                : 'heroicons_solid:arrow-small-down'
                                        "
                                    />
                                    <span>{{
                                        hausse?.change | abs | number: '.2-2'
                                    }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- TODO: Ajouter des params de filtrage pour la page et modifier le lien de cette bouton -->
                <button
                    class="self-center rounded-full border border-[#0045E6] px-3 py-1 font-semibold text-[#0045E6]"
                    (click)="scrollToDashboardAndSetTab(showBaisses ? 2 : 1)"
                >
                    {{ showBaisses ? 'Baisses' : 'Hausses' }}
                </button>
            </div>

            <div class="border-r"></div>

            <div class="flex basis-1/5 flex-col justify-center">
                <app-watchlist />
            </div>
        </div>

        <div
            *ngIf="listeArticles.length > 0"
            class="mx-4 flex items-center gap-4"
        >
            <a
                class="text-nowrap text-xl font-bold text-[#0045E6] hover:underline lg:text-3xl"
                routerLink="/actualites/articles"
            >
                Actualités financières
            </a>
            <div class="w-full border-b-4"></div>
        </div>

        <div *ngIf="listeArticles.length > 0" class="mx-4 flex gap-8">
            <a
                class="flex basis-full flex-col gap-4 self-start lg:basis-1/2 lg:flex-row"
                [routerLink]="[
                    '/actualites/articles',
                    listeArticles[0]?.nom | convertToSlug,
                    listeArticles[0]?.id,
                ]"
            >
                <div class="lg:basis-1/2">
                    <img
                        class="object-cover md:aspect-square"
                        *ngIf="listeArticles[0]?.image"
                        alt="Actualité Financière 1"
                        [ngSrc]="cloud_front + listeArticles[0]?.image"
                        height="150"
                        width="150"
                        priority
                    />
                </div>
                <div
                    *ngIf="listeArticles[0]"
                    class="flex flex-col justify-between gap-1 lg:basis-1/2"
                >
                    <div class="flex flex-col gap-1">
                        <span class="font-bold">{{
                            listeArticles[0]?.nom
                        }}</span>
                        <div
                            class="hidden w-full border-b-2 border-[#0E9667] lg:inline"
                        ></div>
                        <span class="text-[#E70013]">{{
                            ThemeArticle[listeArticles[0]?.themeArticle]
                        }}</span>
                        <p class="line-clamp-5 text-sm">
                            {{ listeArticles[0]?.description }}
                        </p>
                    </div>
                    <div class="flex flex-col gap-1">
                        <span class="text-sm">
                            {{
                                listeArticles[0]?.date
                                    | date: 'EEE dd MMM YYYY, h:mm'
                            }}
                        </span>
                        <span
                            *ngIf="auteurs[listeArticles[0]?.auteur]"
                            class="text-sm uppercase"
                        >
                            Par {{ auteurs[listeArticles[0].auteur]?.userName }}
                        </span>
                    </div>
                </div>
            </a>

            <div
                class="hidden basis-1/4 items-center justify-center bg-gray-300 text-xl lg:flex"
            >
                <a
                    href="https://irbe7.com/actualites/articles/la-bct-et-les-dividendes-bancaires-retour-sur-la-politique-macroprudentielle/67e2c5e91f59c852060b2a58"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="publicité"
                        class="h-full w-full object-cover md:aspect-square"
                        ngSrc="/assets/irbe7/pub1.webp"
                        height="300"
                        width="250"
                    />
                </a>
            </div>

            <!-- TODO: Remplacer cette section par la section commenté lorsque l'api des experts est prêt -->

            <div
                class="hidden basis-1/4 items-center justify-center bg-gray-300 text-xl lg:flex"
            >
                <a
                    class=""
                    href="https://irbe7.com/actualites/articles/lor-valeur-eternelle-dans-un-monde-de-credit-illimite/67dd5bef74cee934929c47d3"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="publicité"
                        class="h-full w-full object-cover md:aspect-square"
                        ngSrc="/assets/irbe7/pub2.webp"
                        height="300"
                        width="250"
                    />
                </a>
            </div>

            <!-- <div class="hidden flex-col items-center basis-1/4 lg:flex">
      <div
        class="flex flex-col gap-3 p-3 border-2 font-semibold rounded-md w-fit"
      >
        <div class="flex gap-4 items-center">
          <span class="uppercase text-nowrap text-[#545151]"
            >les expert.es</span
          >
          <div class="w-full border-b-4 border-[#0E9667]"></div>
        </div>
        <div class="flex flex-col gap-4 text-nowrap">
          <div *ngFor="let expert of experts" class="flex gap-4 items-center">
            <a [routerLink]="['/mur-utilisateur-autre', expert.userName]">
              <img
                class="w-20 h-20 rounded-full"
                [src]="cloud_front + expert?.urlPhotoProfil"
              />
            </a>
            <div class="flex flex-col">
              <span>&#64;{{ expert?.userName }}</span>
              <span class="text-sm text-[#383838]">{{
                expert?.description
              }}</span>
              <span class="text-sm text-[#383838]">Suivi par 0 personnes</span>
              <button
                class="border-[#0E9667] text-[#0E9667] border uppercase rounded-full p-1 mt-2"
              >
                Suivre
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
        </div>

        <div
            *ngIf="listeArticles.length > 1"
            class="mx-4 flex flex-col gap-4 lg:flex-row lg:gap-10"
        >
            <div class="flex basis-3/4 flex-col gap-4 lg:flex-row lg:gap-10">
                <a
                    *ngFor="
                        let article of listeArticles | slice: 1 : 4;
                        index as i
                    "
                    class="flex basis-1/3 flex-col gap-1.5 border-t-2 pt-4 lg:border-t-0 lg:pt-0"
                    [routerLink]="[
                        '/actualites/articles',
                        article?.nom | convertToSlug,
                        article?.id,
                    ]"
                >
                    <img
                        class="object-cover md:aspect-square"
                        *ngIf="article?.image"
                        [alt]="['Actualité Financière' + (i + 2)]"
                        [ngSrc]="cloud_front + article.image"
                        height="150"
                        width="150"
                    />
                    <span class="line-clamp-2 font-bold md:h-11">{{
                        article?.nom
                    }}</span>
                    <div
                        class="hidden w-full border-b-2 border-[#0E9667] lg:inline"
                    ></div>
                    <p class="line-clamp-5 text-sm">
                        {{ article?.description }}
                    </p>
                    <span class="text-sm">
                        {{ article?.date | date: 'EEE dd MMM YYYY, h:mm' }}
                    </span>
                    <span
                        *ngIf="auteurs[article?.auteur]"
                        class="text-sm uppercase"
                    >
                        Par {{ auteurs[article.auteur]?.userName }}
                    </span>
                </a>
            </div>
            <!-- TODO: Remplacer cette section par la section commenté lorsque l'api de calendrier est prêt -->
            <div
                class="hidden basis-1/4 items-center justify-center bg-gray-300 text-xl lg:flex"
            >
                <a
                    class=""
                    href="https://irbe7.com/graphique/TUNINDEX"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="publicité"
                        class="h-full w-full object-cover"
                        ngSrc="/assets/irbe7/pub3.webp"
                        height="45"
                        width="150"
                    />
                </a>
            </div>

            <!-- <div class="hidden flex-col gap-4 basis-1/4 lg:flex">
      <span class="uppercase text-[#0045E6] font-medium text-2xl"
        >Calendrier</span
      >
      <div class="flex flex-col gap-1 border-l-2 pl-3 border-[#0E9667E3]">
        <span class="text-[#0E9667] font-bold">26/07/2024</span>
        <span class="font-light"
          ><span class="text-[#4E4E4E] font-bold uppercase">Titre : </span>EVENT
          EVENT EVENT EVENT</span
        >
        <span class="font-light"
          ><span class="text-[#4E4E4E] font-bold uppercase">Dividende : </span
          >Event XXXXXXXXXX XXXXXXXXXX</span
        >
        <span class="font-light"
          ><span class="text-[#4E4E4E] font-bold uppercase">Place : </span
          >Paris</span
        >
        <span class="font-light"
          ><span class="text-[#4E4E4E] font-bold uppercase">Date : </span>De
          XX/XX/2024 au XX/XX/XX</span
        >
      </div>
      <div class="flex flex-col gap-1 border-l-2 pl-3 border-[#0E9667E3]">
        <span class="text-[#0E9667] font-bold">26/07/2024</span>
        <span class="font-light"
          ><span class="text-[#4E4E4E] font-bold uppercase">Titre : </span>EVENT
          EVENT EVENT EVENT</span
        >
        <span class="font-light"
          ><span class="text-[#4E4E4E] font-bold uppercase">Dividende : </span
          >Event XXXXXXXXXX XXXXXXXXXX</span
        >
        <span class="font-light"
          ><span class="text-[#4E4E4E] font-bold uppercase">Place : </span
          >Paris</span
        >
        <span class="font-light"
          ><span class="text-[#4E4E4E] font-bold uppercase">Date : </span>De
          XX/XX/2024 au XX/XX/XX</span
        >
      </div>
    </div> -->
        </div>

        <div
            *ngIf="listeArticles.length > 1"
            class="flex w-full justify-end px-4 lg:w-3/4"
        >
            <a
                class="flex items-center gap-1 rounded-full px-3 py-1 font-semibold"
                routerLink="/actualites/articles"
                ><span class="text-[#0045E6]">Voir Plus</span
                ><mat-icon
                    class="text-[#0045E6]"
                    svgIcon="heroicons_solid:arrow-small-right"
            /></a>
        </div>

        <div *ngIf="listeNews.length > 0" class="mx-4 flex items-center gap-4">
            <a
                class="text-nowrap text-xl font-bold text-[#0045E6] hover:underline lg:text-3xl"
                routerLink="/actualites/news"
            >
                Fil d'info
            </a>
            <div class="w-full border-b-4"></div>
        </div>

        <div
            *ngIf="listeNews.length > 0"
            class="mx-4 flex flex-col gap-4 lg:flex-row"
        >
            <div class="flex flex-col lg:basis-2/3">
                <div
                    *ngFor="let news of listeNews"
                    class="odd:text-[#545454] even:bg-[#F2F2F2] even:text-[#0045E6]"
                >
                    <a
                        [routerLink]="[
                            '/actualites/news',
                            news?.nom | convertToSlug,
                            news?.id,
                        ]"
                        class="m-2.5 flex items-center gap-2"
                    >
                        <span class="text-sm">{{
                            news?.date | date: 'dd.MM.YYYY'
                        }}</span>
                        <span class="truncate text-ellipsis text-nowrap">{{
                            news?.nom
                        }}</span>
                    </a>
                </div>
                <a
                    class="mt-4 flex items-center gap-1 self-end rounded-full px-3 py-1 font-semibold"
                    routerLink="/actualites/news"
                    ><span class="text-[#0045E6]">Voir Plus</span
                    ><mat-icon
                        class="text-[#0045E6]"
                        svgIcon="heroicons_solid:arrow-small-right"
                /></a>
            </div>
            <div
                *ngIf="isLoggedIn !== undefined && !isLoggedIn"
                class="flex items-center justify-center lg:basis-1/3"
            >
                <div
                    class="flex max-w-72 flex-col items-center justify-evenly gap-6 rounded-lg bg-[#FAFAFA] p-10 shadow-md"
                >
                    <span class="text-center text-xl font-semibold"
                        >Rejoignez notre communauté</span
                    >
                    <button
                        (click)="signup()"
                        class="self-center rounded-xl bg-[#0045E6] px-4 py-2 font-medium uppercase text-white"
                    >
                        Inscription
                    </button>
                    <button
                        (click)="signin()"
                        class="self-center rounded-xl border border-[#0E9667] px-4 py-2 font-medium uppercase text-[#0E9667]"
                    >
                        Connexion
                    </button>
                </div>
            </div>
        </div>

        <div
            *ngIf="listeArticlesInfographie.length > 0"
            class="mx-4 flex items-center gap-4"
        >
            <a
                class="text-nowrap text-xl font-bold text-[#0045E6] hover:underline lg:text-3xl"
                routerLink="/actualites/articles"
            >
                Infographique
            </a>
            <div class="w-full border-b-4"></div>
        </div>
        <app-infographique-carousel
            *ngIf="listeArticlesInfographie.length > 0"
            [listeArticlesInfographie]="listeArticlesInfographie"
            class="mx-4"
        />

        <div class="mx-4 hidden items-center gap-4 lg:flex">
            <a
                class="text-nowrap text-xl font-bold text-[#0045E6] hover:underline lg:text-3xl"
                routerLink="/actifs"
            >
                Dashboard de marché
            </a>
            <div class="w-full border-b-4"></div>
        </div>
        <div
            #dashboardContainer
            class="mx-auto hidden w-fit flex-col px-4 lg:flex"
        >
            <div class="flex justify-center">
                <div class="flex flex-col">
                    <button
                        class="border-[#5B5CA9] p-4 text-start"
                        (click)="dashboardIndex = i"
                        [ngClass]="{
                            'border-y border-l bg-gray-50 font-semibold':
                                dashboardIndex === i,
                            'border-b border-r border-b-[#E2E8F0]':
                                dashboardIndex !== i,
                        }"
                        *ngFor="let item of dashboardData; index as i"
                    >
                        {{ item.tab }}
                    </button>
                    <div class="flex-1 border-r border-[#5B5CA9]"></div>
                </div>
                <div class="border-y border-r border-[#5B5CA9] bg-gray-50 p-4">
                    <table *ngIf="dashboardData?.length">
                        <thead>
                            <tr class="bg-[#0045E6] text-white">
                                <th class="w-48 p-3">Valeur</th>
                                <th class="w-28 p-3">Cours</th>
                                <th class="w-28 p-3">Titres</th>
                                <th class="w-28 p-3">%Variation</th>
                                <th class="w-28 p-3">Volume (DT)</th>
                                <!-- <th class="w-28 p-3">5 Performance/jour</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="border-b last:border-none"
                                *ngFor="
                                    let item of dashboardData[dashboardIndex]
                                        .data
                                "
                            >
                                <td
                                    class="w-48 p-3 font-semibold text-[#545454]"
                                >
                                    <a
                                        class="hover:underline"
                                        [routerLink]="[
                                            '/actifs',
                                            item.valeur,
                                            item.isin,
                                        ]"
                                        >{{ item.valeur }}</a
                                    >
                                </td>
                                <td class="w-28 p-3 text-center font-semibold">
                                    {{ item.cours | number: '.2-2' }}
                                </td>
                                <td class="w-28 p-3 text-center">
                                    {{ item.titres | abs | number: '.0-0' }}
                                </td>
                                <td
                                    class="flex w-28 items-center justify-center p-3"
                                    [ngClass]="{
                                        'text-[#E70013]': item.variation < 0,
                                        'text-[#0E9667]': item.variation >= 0,
                                    }"
                                >
                                    <mat-icon
                                        class="icon-size-4"
                                        [ngClass]="{
                                            'text-[#E70013]':
                                                item.variation < 0,
                                            'text-[#0E9667]':
                                                item.variation >= 0,
                                        }"
                                        [svgIcon]="
                                            item.variation >= 0
                                                ? 'heroicons_solid:arrow-small-up'
                                                : 'heroicons_solid:arrow-small-down'
                                        "
                                    />
                                    <span>{{
                                        item.variation | abs | number: '.2-2'
                                    }}</span>
                                </td>
                                <td class="w-28 p-3 text-center">
                                    {{ item.volume | number: '.0-0' }}
                                </td>
                                <!-- <td class="w-28">
                                <div
                                    class="flex items-center justify-center gap-1"
                                >
                                    <div
                                        class="h-2.5 w-8"
                                        [ngClass]="{
                                            'bg-[#E70013]': !element,
                                            'bg-[#0E9667]': element,
                                        }"
                                        *ngFor="let element of item.performance"
                                    ></div>
                                    <button>
                                        <mat-icon
                                            class="font-bold text-[#545454] icon-size-4"
                                            svgIcon="heroicons_solid:plus"
                                        />
                                    </button>
                                </div>
                            </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <a
                class="mt-4 flex items-center gap-1 self-end px-3 py-1 font-semibold"
                routerLink="/actifs"
                ><span class="text-[#0045E6]">Voir Plus</span
                ><mat-icon
                    class="text-[#0045E6]"
                    svgIcon="heroicons_solid:arrow-small-right"
            /></a>
        </div>

        <div class="mx-4 flex items-center gap-4">
            <a
                class="text-nowrap text-xl font-bold text-[#0045E6] hover:underline lg:text-3xl"
                routerLink="/emission/podcast"
            >
                Podcast
            </a>
            <div class="w-full border-b-4"></div>
        </div>
        <app-podcast-carousel class="bg-[#F4F4F4] px-4" />

        <a
            class="mx-4"
            href="https://formation.irbe7.com/formations/"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                alt="publicité"
                class="max-w-280 justify-self-center"
                ngSrc="/assets/irbe7/pub5.webp"
                height="45"
                width="150"
            />
        </a>

        <!-- <div class="flex gap-4 items-center">
    <div class="uppercase text-[#0045E6] font-bold text-nowrap text-xl lg:text-5xl">
      apprendre
    </div>
    <div class="border-b-4 w-full"></div>
  </div>
  <app-apprendre-carousel /> -->

        <div
            *ngIf="listeArticlesEducatifsDebutant.length > 0"
            class="mx-4 flex items-center gap-4"
        >
            <a
                class="text-nowrap text-xl font-bold text-[#0045E6] hover:underline lg:text-3xl"
                routerLink="/actualites/articles"
            >
                Articles éducatifs
            </a>
            <div class="w-full border-b-4"></div>
        </div>

        <div
            *ngIf="listeArticlesEducatifsDebutant.length > 0"
            class="mx-4 flex flex-col gap-8 lg:flex-row"
        >
            <div class="lg:basis-3/4">
                <div class="flex flex-col gap-4">
                    <div class="flex">
                        <a
                            class="flex basis-full flex-col gap-4 lg:basis-2/3 lg:flex-row"
                            [routerLink]="[
                                '/actualites/articles',
                                listeArticlesEducatifsDebutant[0]?.nom
                                    | convertToSlug,
                                listeArticlesEducatifsDebutant[0]?.id,
                            ]"
                        >
                            <div class="lg:basis-1/2">
                                <img
                                    class="object-cover md:aspect-square"
                                    *ngIf="
                                        listeArticlesEducatifsDebutant[0]?.image
                                    "
                                    alt="Article Éducatif 1"
                                    [ngSrc]="
                                        cloud_front +
                                        listeArticlesEducatifsDebutant[0].image
                                    "
                                    height="150"
                                    width="150"
                                />
                            </div>
                            <div
                                class="flex flex-col justify-between gap-1 lg:basis-1/2"
                            >
                                <div class="flex flex-col gap-1">
                                    <span class="font-bold">{{
                                        listeArticlesEducatifsDebutant[0]?.nom
                                    }}</span>
                                    <div
                                        class="hidden w-full border-b-2 border-[#0E9667] lg:inline"
                                    ></div>
                                    <span class="text-[#E70013]"
                                        >{{
                                            ThemeArticle[
                                                listeArticlesEducatifsDebutant[0]
                                                    ?.themeArticle
                                            ]
                                        }}
                                    </span>
                                    <p class="line-clamp-5 text-sm">
                                        {{
                                            listeArticlesEducatifsDebutant[0]
                                                ?.description
                                        }}
                                    </p>
                                </div>
                                <div class="flex flex-col gap-1">
                                    <span class="text-sm">
                                        {{
                                            listeArticlesEducatifsDebutant[0]
                                                ?.date
                                                | date: 'EEE dd MMM YYYY, h:mm'
                                        }}
                                    </span>
                                    <span
                                        *ngIf="
                                            auteurs[
                                                listeArticlesEducatifsDebutant[0]
                                                    ?.auteur
                                            ]
                                        "
                                        class="text-sm uppercase"
                                    >
                                        Par
                                        {{
                                            auteurs[
                                                listeArticlesEducatifsDebutant[0]
                                                    ?.auteur
                                            ].userName
                                        }}
                                    </span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="flex flex-col gap-4 lg:flex-row lg:gap-8">
                        <a
                            *ngFor="
                                let article of listeArticlesEducatifsDebutant
                                    | slice: 1 : 4;
                                index as i
                            "
                            class="flex basis-1/3 flex-col gap-1.5 border-t-2 pt-4 lg:border-t-0 lg:pt-0"
                            [routerLink]="[
                                '/actualites/articles',
                                article?.nom | convertToSlug,
                                article?.id,
                            ]"
                        >
                            <img
                                class="object-cover md:aspect-square"
                                *ngIf="article?.image"
                                [alt]="['Article Éducatif' + (i + 2)]"
                                [ngSrc]="cloud_front + article.image"
                                height="150"
                                width="150"
                            />
                            <span class="line-clamp-2 font-bold md:h-11">{{
                                article?.nom
                            }}</span>
                            <div
                                class="hidden w-full border-b-2 border-[#0E9667] lg:inline"
                            ></div>
                            <p class="line-clamp-5">
                                {{ article?.description }}
                            </p>
                            <span class="text-sm">
                                {{
                                    article?.date
                                        | date: 'EEE dd MMM YYYY, h:mm'
                                }}
                            </span>
                            <span
                                *ngIf="auteurs[article.auteur]"
                                class="text-sm uppercase"
                            >
                                Par {{ auteurs[article.auteur]?.userName }}
                            </span>
                        </a>
                    </div>
                    <a
                        class="mt-4 flex items-center gap-1 self-end rounded-full px-3 py-1 font-semibold"
                        routerLink="/actualites/articles"
                    >
                        <span class="text-[#0045E6]">Voir Plus</span
                        ><mat-icon
                            class="text-[#0045E6]"
                            svgIcon="heroicons_solid:arrow-small-right"
                        />
                    </a>
                </div>
            </div>
            <div class="hidden  text-xl lg:flex lg:basis-1/4">
                <a
                    href="https://irbe7.com/actualites/articles/quand-trump-bouscule-wall-street-anatomie-dune-economie-en-transition/67d2e6c17ef49655d809f246"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="publicité"
                        ngSrc="/assets/irbe7/pub6.webp"
                        height="350"
                        width="350"
                    />
                </a>
            </div>
        </div>

    <!--    <div
            class="mx-4 flex h-60 items-center justify-center text-xl lg:mx-20 lg:my-10"
        >
        publicité 
        <a
        class="mx-4"
        [routerLink]="['/emission/de-salarie-a-investisseur-a-la-bourse-de-tunis/eEZtoBDeZdo']"
        src="https://i.ytimg.com/vi/eEZtoBDeZdo/mqdefault.jpg"
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            alt="publicité"
            class="max-w-280 justify-self-center"
            ngSrc="/assets/irbe7/navbar-image.webp"
            height="45"
            width="150"
        />
    </a>  
   
  </div>  -->

        <div
            *ngIf="listeArticlesNouvellesDirigeants.length > 0"
            class="mx-4 flex items-center gap-4"
        >
            <a
                class="text-nowrap text-xl font-bold text-[#0045E6] hover:underline lg:text-3xl"
                routerLink="/actualites/articles"
            >
                Les nouvelles des dirigeants
            </a>
            <div class="w-full border-b-4"></div>
        </div>

        <div
            *ngIf="listeArticlesNouvellesDirigeants.length > 0"
            class="mx-4 flex flex-col gap-8 lg:flex-row"
        >
            <div class="flex flex-col gap-4 lg:basis-3/4">
                <a
                    class="flex flex-col gap-4 border-t-2 pt-4 first:border-t-0 first:pt-0 lg:flex-row"
                    [routerLink]="[
                        '/actualites/articles',
                        article?.nom | convertToSlug,
                        article?.id,
                    ]"
                    *ngFor="
                        let article of listeArticlesNouvellesDirigeants;
                        index as i
                    "
                >
                    <img
                        class="object-cover lg:h-40 lg:w-40"
                        [alt]="['Article Dirigeant' + (i + 1)]"
                        [ngSrc]="cloud_front + article.image"
                        height="160"
                        width="160"
                    />
                    <div class="flex flex-col justify-evenly">
                        <span class="font-bold">{{ article?.nom }}</span>
                        <p class="line-clamp-5">
                            {{ article?.description }}
                        </p>
                        <span>{{
                            article?.date | date: 'EEE dd MMM YYYY, h:mm'
                        }}</span>
                    </div>
                </a>
                <a
                    class="mt-4 flex items-center gap-1 self-end rounded-full px-3 py-1 font-semibold"
                    routerLink="/actualites/articles"
                    ><span class="text-[#0045E6]">Voir Plus</span
                    ><mat-icon
                        class="text-[#0045E6]"
                        svgIcon="heroicons_solid:arrow-small-right"
                /></a>
            </div>
            <div
                *ngIf="isLoggedIn !== undefined"
                class="flex w-full items-center justify-center lg:basis-1/4"
            >
                <div
                    class="flex max-w-72 flex-col items-center justify-between gap-12 rounded-md border border-[#91AFF4] p-6 shadow-[2px_2px_3px_0px_#0045E670]"
                >
                    <span class="text-center"
                        >Envie de voir vos articles mis en avant sur notre page
                        d'accueil?</span
                    >
                    <button
                        class="rounded-full bg-[#0045E6] p-2 text-sm font-bold text-white"
                        (click)="contribute()"
                    >
                        Devenez un contributeur
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div
        class="fixed inset-0 z-40 flex flex-col items-center overflow-y-scroll bg-white transition-all duration-150 ease-in-out md:hidden"
        [ngClass]="{
            'translate-x-0 translate-y-0': showStats,
            'translate-x-full translate-y-full': !showStats,
        }"
    >
        <app-actif-search class="self-stretch p-4" />
        <app-stats-cards class="p-4" />
    </div>
    <button
        (click)="this.showStats = !this.showStats"
        class="fixed bottom-8 right-4 z-49 block h-12 w-16 rounded bg-blue-800 md:hidden"
    >
        <mat-icon
            class="text-white"
            [svgIcon]="showStats ? 'feather:x' : 'mat_outline:query_stats'"
            [ngClass]="showStats ? 'icon-size-5' : 'icon-size-10'"
        />
    </button>
</div>
