import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

export function AppGuard(): boolean {
    const isLogged = inject(LoginService).getIsLogged();
    const router = inject(Router);

    if (isLogged === undefined) return false;

    if (!isLogged) {
        router.navigate(['/']);
        return false;
    }

    return true;
}
