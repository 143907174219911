
export const Etape = [
    {
        order   : 0,
        title   : "Type de l'article",
        content : `<h2 class="text-2xl sm:text-3xl">Type de l'article</h1>`
    },
    {
        order   : 1,
        title   : "Composer l'article",
        content : `<h2 class="text-2xl sm:text-3xl">Composer l'article</h1>`
    },
    {
        order   : 2,
        title   : "Détails de l'article",
        content : `<h2 class="text-2xl sm:text-3xl">Détails de l'article</h1>`
    },
    {
        order   : 3,
        title   : "Actif",
        content : `<h2 class="text-2xl sm:text-3xl">Actif</h1>`
    },
    {
        order   : 4,
        title   : "Valider l'article",
        content : `<h2 class="text-2xl sm:text-3xl">Valider l'article</h1>`
    },
];