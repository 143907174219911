import { DialogModule } from '@angular/cdk/dialog';
import {
    CommonModule,
    DatePipe,
    isPlatformBrowser,
    NgOptimizedImage,
} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HashMap } from '@ngneat/transloco';
import { LoginService } from 'app/login.service';
import { Article } from 'app/models/Article';
import { MarketData } from 'app/models/MarketData';
import { Utilisateur } from 'app/models/Utilisateur';
import { AbsPipe } from 'app/shared/abs.pipe';
import { videosBourseMag } from 'app/shared/BourceMag';
import { ConvertToSlugPipe } from 'app/shared/convert-to-slug.pipe';
import { DataService } from 'app/shared/data.service';
import { MetaService } from 'app/shared/meta.service';
import { SharedModule } from 'app/shared/shared.module';
import { environment } from 'environments/environment';
import type { IChartApi, ISeriesApi } from 'lightweight-charts';
import { QuillModule } from 'ngx-quill';
import { ActifSearchComponent } from '../actif-search/actif-search.component';
import { PopupLoginComponent } from '../popup-login/popup-login.component';
import { PopupSignUpComponent } from '../popup-signup/popup-signup.component';
import { ThemeArticle } from './../../models/ThemeArticle';
import { IndexCardComponent } from './index-card/index-card.component';
import { InfographiqueCarouselComponent } from './infographique-carousel/infographique-carousel.component';
import { PodcastCarouselComponent } from './podcast-carousel/podcast-carousel.component';
import { StatsCardsComponent } from './stats-cards/stats-cards.component';
import { WatchlistComponent } from './watchlist/watchlist.component';
import { LinkService } from 'app/shared/Link.service';

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgOptimizedImage,
        CommonModule,
        DialogModule,
        MatIconModule,
        QuillModule,
        SharedModule,
        MatListModule,
        DatePipe,
        RouterModule,
        AbsPipe,
        IndexCardComponent,
        InfographiqueCarouselComponent,
        PodcastCarouselComponent,
        ConvertToSlugPipe,
        StatsCardsComponent,
        ActifSearchComponent,
        WatchlistComponent,
    ],
})
export class MediaComponent implements OnInit, AfterViewInit {
    @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;
    @ViewChild('scrollContainer', { static: true })
    scrollContainer!: ElementRef;
    @ViewChild('tooltipContainer', { static: true })
    tooltipContainer: ElementRef;
    @ViewChild('dashboardContainer', { static: false })
    dashboardContainer!: ElementRef;
    cloud_front = environment.cloud_front_host;
    showBaisses: boolean = false;
    showStartGradient: boolean = false;
    showEndGradient: boolean = false;
    isLoggedIn?: boolean;
    showStats: boolean = false;
    showTooltip: boolean = false;
    populaires: boolean = true;
    tooltipValue: number;
    dashboardIndex: number = 0;
    tooltipDate?: Date;
    tunindex: MarketData;
    tunindex20: MarketData;
    displayedColumnsTabIndice: string[] = ['Actifs', 'Volume (DT)'];
    listeNews: Article[];
    listeArticles: Article[];
    listeArticlesEducatifsDebutant: Article[];
    listeArticlesNouvellesDirigeants: Article[];
    listeArticlesInfographie: Article[];
    baisses: MarketData[];
    volumes: MarketData[];
    hausses: MarketData[];
    volumeTotal: number;
    ThemeArticle = ThemeArticle;
    auteurs: HashMap<Utilisateur> = {};
    videosBourseMag = videosBourseMag.slice(0, 4);
    dashboardData = [
        { tab: 'Les plus actifs - Volume', data: [] },
        { tab: 'Top HAUSSES', data: [] },
        { tab: 'Top BAISSES', data: [] },
    ];
    selectValue = '1D';
    marketStatus: string = 'Fermé';
    private refreshIntervalId: string;
    private chart: IChartApi;
    private lineSeries: ISeriesApi<'Area'>;
    private _graphToShow: 'TUNINDEX' | 'TUNINDEX20' = 'TUNINDEX';

    @Input()
    set graphToShow(value: 'TUNINDEX' | 'TUNINDEX20') {
        this._graphToShow = value;
        this.lineSeries.setData([]);
        this.chargerGraphique();
    }

    get graphToShow(): 'TUNINDEX' | 'TUNINDEX20' {
        return this._graphToShow;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private _changeDetectorRef: ChangeDetectorRef,
        private dataService: DataService,
        private loginService: LoginService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private metaService: MetaService,
        private router: Router,private seoService: LinkService,
        
    ) {
        let principaux: MarketData[] = [];
        this.isLoggedIn = this.loginService.getIsLogged();
        ({
            media: {
                listeArticles: this.listeArticles,
                listeNews: this.listeNews,
                listeArticlesEducatifsDebutant:
                    this.listeArticlesEducatifsDebutant,
                listeArticlesNouvellesDirigeants:
                    this.listeArticlesNouvellesDirigeants,
                listeArticlesInfographie: this.listeArticlesInfographie,
                tunindex: this.tunindex,
                tunindex20: this.tunindex20,
                auteurs: this.auteurs,
            },
            principaux: [this.volumes, this.hausses, this.baisses, principaux],
        } = this.route.snapshot.data);

        this.volumeTotal = principaux.reduce(
            (acc, indice) => acc + indice.volume,
            0
        );

        for (let i = 0; i <= 4; i++) {
            this.dashboardData[0].data.push({
                valeur: this.volumes[i].referentiel.stockName,
                cours: this.volumes[i].last,
                titres: this.volumes[i].caps,
                variation: this.volumes[i].change,
                volume: this.volumes[i].volume,
                performance: [0, 1, 0, 0, 1],
                isin: this.volumes[i].referentiel.isin,
            });
            this.dashboardData[1].data.push({
                valeur: this.hausses[i].referentiel.stockName,
                cours: this.hausses[i].last,
                titres: this.hausses[i].caps,
                variation: this.hausses[i].change,
                volume: this.hausses[i].volume,
                performance: [0, 1, 0, 0, 1],
                isin: this.hausses[i].referentiel.isin,
            });
            this.dashboardData[2].data.push({
                valeur: this.baisses[i].referentiel.stockName,
                cours: this.baisses[i].last,
                titres: this.baisses[i].caps,
                variation: this.baisses[i].change,
                volume: this.baisses[i].volume,
                performance: [0, 1, 0, 0, 1],
                isin: this.baisses[i].referentiel.isin,
            });
        }
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            (async () => {
                const { createChart } = await import('lightweight-charts');

                window.addEventListener('resize', () => this.checkScroll());
                this.checkScroll();
                this.chart = createChart(this.chartContainer.nativeElement, {
                    height: this.chartContainer.nativeElement.clientHeight,
                    width: this.chartContainer.nativeElement.clientWidth,
                    timeScale: {
                        timeVisible: true,
                        secondsVisible: false,
                    },
                    localization: {
                        timeFormatter: (time) => {
                            const date = new Date(time * 1000); // Convert Lightweight Charts timestamp to milliseconds
                            return date.toLocaleString('fr', {
                                timeZone: 'Africa/Tunis',
                                hour: '2-digit',
                                minute: '2-digit',
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                            });
                        },
                    },
                    watermark: {
                        visible: true,
                        fontSize: 24,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: '#bcbcbc',
                        text: 'Irbe7',
                    },
                    autoSize: true,
                    layout: {
                        background: {
                            color: '#fbfbfb',
                        },
                    },
                });
                this.lineSeries = this.chart.addAreaSeries({
                    lineColor: '#1b51af', // Line color set to blue
                    topColor: 'rgba(27, 81, 175, 0.5)', // Shadow color at the top, matching the line color with transparency
                    bottomColor: 'rgba(27, 81, 175, 0.1)', // Shadow color at the bottom for a gradient effect
                    lineWidth: 1,
                });
                window.document.getElementById('tv-attr-logo').style.display =
                    'none';
                this.chart.subscribeCrosshairMove((param) => {
                    if (
                        param.point === undefined ||
                        !param.time ||
                        param.point.x < 0 ||
                        param.point.x >
                            this.chartContainer.nativeElement.clientWidth ||
                        param.point.y < 0 ||
                        param.point.y >
                            this.chartContainer.nativeElement.clientHeight
                    ) {
                        this.showTooltip = false;
                        this._changeDetectorRef.markForCheck();
                    } else {
                        const data: any = param.seriesData.get(this.lineSeries);
                        if (data) {
                            this.showTooltip = true;
                            this.tooltipDate = new Date(data.time * 1000);
                            this.tooltipValue = data.value.toFixed(2);
                            this.tooltipContainer.nativeElement.style.left =
                                param.point.x -
                                this.tooltipContainer.nativeElement
                                    .offsetWidth /
                                    2 +
                                'px';
                            this.tooltipContainer.nativeElement.style.top =
                                param.point.y -
                                this.tooltipContainer.nativeElement
                                    .offsetHeight -
                                5 +
                                'px';
                            this._changeDetectorRef.markForCheck();
                        }
                    }
                });
            })();
        }
        this.chargerGraphique();
    }

    async ngOnInit() {
        this.metaService.updateMetaTags({
            title: "Irbe7 - L'actualité de la bourse de Tunis",
            description:
                "Suivez l'actualité boursiére et financiére en temps réel avec Irbe7.com. Analyses de marché, indices boursiers, actions, devises, matières premières et plus encore. la source d'information pour les investisseurs intelligents.",
            canonicalLink: environment.apiUrl ,
            keywords:
                'finance, bourse de Tunis, marché boursier, investissement, actions, devises, matières premières, analyse technique, analyse fondamentale, économie, Tunisie, marché tunisien, actualités financières, indices boursiers, inflation',
            ogTitle: "Irbe7 - L'actualité de la bourse de Tunis",
            ogUrl: environment.apiUrl ,
            ogDescription:
                "Suivez l'actualité boursiére et financiére en temps réel avec Irbe7.com. Analyses de marché, indices boursiers, actions, devises, matières premières et plus encore. la source d'information pour les investisseurs intelligents.",
            twitterTitle: "Irbe7 - L'actualité de la bourse de Tunis",
            twitterDescription:
                "Suivez l'actualité boursiére et financiére en temps réel avec Irbe7.com. Analyses de marché, indices boursiers, actions, devises, matières premières et plus encore. la source d'information pour les investisseurs intelligents.",
        });
        this.createLinkForCanonicalURL();
        this.checkMarketStatus();

    }
    createLinkForCanonicalURL() {
        this.seoService.createLinkForCanonicalURL();
    }
    checkMarketStatus(): void {
        if (!this.tunindex) {
          this.marketStatus = 'Fermé';
          return;
        }
      
        // Parse the session date from 'seance' (e.g., "18 févr. 2025")
        const seanceStr = this.tunindex.seance;
        const [dayStr, monthAbbrevDot, yearStr] = seanceStr.split(' ');
        const monthAbbrev = monthAbbrevDot.replace('.', ''); // Remove the dot from abbreviation
        const day = parseInt(dayStr, 10);
        const year = parseInt(yearStr, 10);
      
        // Map French month abbreviations to JavaScript month indices (0-11)
        const frenchMonths: { [key: string]: number } = {
          'janv': 0, 'févr': 1, 'mars': 2, 'avr': 3, 'mai': 4,
          'juin': 5, 'juil': 6, 'août': 7, 'sept': 8, 'oct': 9,
          'nov': 10, 'déc': 11
        };
      
        const monthIndex = frenchMonths[monthAbbrev];
        if (monthIndex === undefined) {
          console.error('Invalid month abbreviation:', monthAbbrev);
          this.marketStatus = 'Fermé';
          return;
        }
      
        const sessionDate = new Date(year, monthIndex, day);
        const currentDate = new Date();
      
        // Check if session date is today
        const isSameDay =
          sessionDate.getFullYear() === currentDate.getFullYear() &&
          sessionDate.getMonth() === currentDate.getMonth() &&
          sessionDate.getDate() === currentDate.getDate();
      
        // Define market hours (9:00 to 14:50)
        const marketOpenHour = 9;
        const marketCloseHour = 14;
        const marketCloseMinute = 50;
      
        // Current time components
        const currentHours = currentDate.getHours();
        const currentMinutes = currentDate.getMinutes();
      
        // Check if current time is within trading hours
        const isAfterOpen = currentHours > marketOpenHour || 
          (currentHours === marketOpenHour && currentMinutes >= 0);
        const isBeforeClose = currentHours < marketCloseHour || 
          (currentHours === marketCloseHour && currentMinutes <= marketCloseMinute);
        const isTradingTime = isAfterOpen && isBeforeClose;
      
        this.marketStatus = isSameDay && isTradingTime ? 'Ouvert' : 'Fermé';
      }
    ngOnDestroy(): void {
        clearInterval(this.refreshIntervalId);
        if (isPlatformBrowser(this.platformId))
            window.removeEventListener('resize', () => this.checkScroll());
    }

    checkScroll() {
        if (this.scrollContainer) {
            const hasScroll =
                this.scrollContainer.nativeElement.scrollWidth >
                this.scrollContainer.nativeElement.clientWidth;

            if (hasScroll) {
                this.showStartGradient =
                    this.scrollContainer.nativeElement.scrollLeft > 0;
                const isAtEnd =
                    this.scrollContainer.nativeElement.scrollLeft +
                        this.scrollContainer.nativeElement.clientWidth >=
                    this.scrollContainer.nativeElement.scrollWidth - 1;
                this.showEndGradient = !isAtEnd;
            } else {
                this.showStartGradient = false;
                this.showEndGradient = false;
            }
        }
    }

    chargerGraphique() {
        if (isPlatformBrowser(this.platformId)) {
            this.dataService
                .recupererMiniChart(
                    this.graphToShow,
                    this.selectValue,
                    Math.floor(new Date().getTime() / 1000),
                    2
                )
                .subscribe((data) => {
                    this.lineSeries.setData(data.data);
                });
        }
    }

    onSelectChange(event) {
        this.selectValue = event.target.value;
        this.lineSeries.setData([]);
        this.chargerGraphique();
    }

    signin(): void {
        this.dialog.open(PopupLoginComponent);
    }

    signup(): void {
        this.dialog.open(PopupSignUpComponent);
    }

    redirectPub(pubUrl) {
        if (typeof window !== 'undefined') window.open(pubUrl, '_blank');
    }

    contribute() {
        if (this.isLoggedIn !== undefined)
            if (!this.isLoggedIn) this.dialog.open(PopupLoginComponent);
            else this.router.navigate(['/actualites/creation-article']);
    }

    scrollToDashboardAndSetTab(dashboardIndex: number) {
        if (isPlatformBrowser(this.platformId)) {
            this.dashboardIndex = dashboardIndex;
            this.dashboardContainer.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }
}
