import { CommonModule, DOCUMENT, Location ,isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,PLATFORM_ID,
    ViewEncapsulation,
} from '@angular/core';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Article } from 'app/models/Article';
import { cloneDeep } from 'lodash-es';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Etape } from './../etape';

import { DialogModule } from '@angular/cdk/dialog';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FuseAlertType } from '@fuse/components/alert';
import { LoginService } from 'app/login.service';
import { MarketData } from 'app/models/MarketData';
import { Utilisateur } from 'app/models/Utilisateur';
import { ArticleService } from 'app/shared/article.service';
import { DataService } from 'app/shared/data.service';
import { DirtyComponent } from 'app/shared/dirty-component';
import { ImageService } from 'app/shared/image.service';
import { SharedModule } from 'app/shared/shared.module';
import { UtilisateurService } from 'app/shared/utilisateur.service';
import { environment } from 'environments/environment';
import { QuillModule } from 'ngx-quill';

@Component({
    selector: 'app-creation-article',
    templateUrl: './creation-article.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        DialogModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        RouterModule,
        MatInputModule,
        MatMenuModule,
        MatSidenavModule,
        MatTooltipModule,
        SharedModule,
        MatTabsModule,
        MatProgressBarModule,
        QuillModule,
        CommonModule,
        MatPaginatorModule,
        MatTableModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatRadioModule,
        CommonModule,
        MatBadgeModule,
        MatListModule,
    ],
})
export class CreationArticleComponent
    implements OnInit, DirtyComponent, OnDestroy
{
    cloud_front = environment.cloud_front_host;
    articleForm: FormGroup;
    @ViewChild('articleNgForm') articleNgForm: NgForm;
    selectedFiles?: FileList;
    currentFile?: File;
    fileInfos?: Observable<any>;
    @ViewChild('courseSteps', { static: true }) courseSteps: MatTabGroup;
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    currentStep: number = 0;
    article: Article;
    totalSteps: number = 5;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _demoSteps: any[] = Etape;
    steps: any[] = Etape;
    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: '',
    };
    showAlert: boolean = false;
    isDirty = false;
    formFieldHelpers: string[] = [''];
    articleSauvegarde: boolean = false;
    articleNonSauvegarde: boolean = false;
    idUtilisateurConnecte: string = '';
    isLogged: boolean;
    username: string;
    actifs: MarketData[] = [];
    selectedActifNom: any;
    selectedFile: File | null = null;
    photoArticle: string;
    allowedUsernames: string[] = ['fida12', 'anis habibi','mannana dbibi'];
    isButtonEnabled: boolean = false;

    constructor(
        public location: Location,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: Document,
        private imageService: ImageService,
        private articleService: ArticleService,
        private utilisateurService: UtilisateurService,
        private loginService: LoginService,
        private dataService: DataService, @Inject(PLATFORM_ID) private platformId: Object
    ) {}
    canDeactivate() {
        return this.isDirty;
    }

    ngOnInit() {
        this.articleForm = new FormGroup({
            contenu: new FormControl('', [Validators.required]),
            nom: new FormControl('', [Validators.required]),
            image: new FormControl(''),
            type: new FormControl('article', [Validators.required]),
            auteur: new FormControl(''),
            themeArticle: new FormControl(''),
            themeNews: new FormControl(''),
            description: new FormControl('', [Validators.required]),
            actif: new FormControl(''),
            etat: new FormControl('VALIDE', [Validators.required]),
        });
        
        // Conditional Validators (optional)
        this.articleForm.get('type').valueChanges.subscribe(value => {
            if (value === 'article') {
              this.articleForm.get('themeNews').reset();
            } else if (value === 'news') {
              this.articleForm.get('themeArticle').reset();
            }
          });
        this.articleForm.valueChanges.subscribe((e) => (this.isDirty = true));
        this.fileInfos = this.articleService.getFiles();
        this.steps = cloneDeep(this._demoSteps);
        // Subscribe to media changeslo
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        this.recupererPopulaires();
        this.checkButtonStatus();

    }
    checkButtonStatus(): void {
        this.username = this.loginService.getUsername();
        if (this.allowedUsernames.includes(this.username)) {
          this.isButtonEnabled = true;
        }
      }
    retour() {
        this.location.back();
    }
    onActifNomSelectionChange(selectedActifNom: string | string[]) {
        if (Array.isArray(selectedActifNom)) {
            this.selectedActifNom.setValue(selectedActifNom.join(', '));
        } else {
            this.selectedActifNom.setValue(selectedActifNom);
        }
    }
    recupererPopulaires() {
        this.dataService
            .recupererTout()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (data: MarketData[]) => {
                    this.actifs = data;
                },
            });
    }
    async soumettre() {
        try {
          await this.uploadImageAndSaveArticle();
          this.goToNextStep();
        } catch (error) {
          console.log('Error during submission:', error);
        }
      }
    async uploadImageAndSaveArticle() {
        if (this.selectedFile) {
          return new Promise<void>((resolve, reject) => {
            this.imageService
              .uploadImageInFolder(this.selectedFile, 'Article')
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe({
                next: (response) => {
                  const imageUrl = response.imagePath;
                  this.articleForm.controls['image'].setValue(imageUrl);
                  this.enregistrerArticle().then(resolve).catch(reject);
                },
                error: (err) => {
                  console.log('Error uploading image:', err);
                  reject(err);
                },
              });
          });
        } else {
          console.log('No file selected');
        }
      }
    
      async enregistrerArticle() {
        return new Promise<void>((resolve, reject) => {
          const formData = this.articleForm.value;
          let dataToSend = {
            nom: formData.nom,
            type: formData.type,
            contenu: formData.contenu,
            image: formData.image,
            auteur: formData.auteur,
            date: formData.date,
            etat: formData.etat,
            actif: formData.actif,
            description: formData.description
          };
    
          if (formData.type === 'news') {
            dataToSend['themeNews'] = formData.themeNews;
          } else if (formData.type === 'article') {
            dataToSend['themeArticle'] = formData.themeArticle;
          }
    
          this.isLogged = this.loginService.getIsLogged();
          if (this.isLogged) {
            this.username = this.loginService?.getUsername();
            this.utilisateurService
              .getUtilisateurByUsername(this.username)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((data: Utilisateur[]) => {
                this.idUtilisateurConnecte = data[0].userId;
                dataToSend['auteur'] = this.idUtilisateurConnecte;
    
                this.articleService
                  .enregistrer(dataToSend)
                  .pipe(takeUntil(this._unsubscribeAll))
                  .subscribe({
                    next: () => {
                      this.articleSauvegarde = true;
                      resolve();
                    },
                    error: (err) => {
                      this.articleNonSauvegarde = true;
                      console.log('Error saving article:', err);
                      reject(err);
                    },
                  });
              });
          }
        });
      }
    
    
    selectFile(event: any): void {
        this.selectedFile = event.target.files[0];
        if (this.selectedFile) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.photoArticle = e.target.result;
            };
            reader.readAsDataURL(this.selectedFile);
        }
    }

    openInput() {
        document.getElementById('fileInput').click();
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Go to given step
     *
     * @param step
     */
    goToStep(step: number): void {
        // Set the current step
        this.currentStep = step;

        // Go to the step
        this.courseSteps.selectedIndex = this.currentStep;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Go to previous step
     */
    goToPreviousStep(): void {
        // Return if we already on the first step
        if (this.currentStep === 0) {
            return;
        }

        // Go to step
        this.goToStep(this.currentStep - 1);

        // Scroll the current step selector from sidenav into view
        this._scrollCurrentStepElementIntoView();
    }

    /**
     * Go to next step
     */
    goToNextStep(): void {
        // Return if we already on the last step
        if (this.currentStep === this.totalSteps - 1) {
            return;
        }

        // Go to step
        this.goToStep(this.currentStep + 1);

        // Scroll the current step selector from sidenav into view
        this._scrollCurrentStepElementIntoView();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Scrolls the current step element from
     * sidenav into the view. This only happens when
     * previous/next buttons pressed as we don't want
     * to change the scroll position of the sidebar
     * when the user actually clicks around the sidebar.
     *
     * @private
     */
    private _scrollCurrentStepElementIntoView(): void {
        // Wrap everything into setTimeout so we can make sure that the 'current-step' class points to correct element
        setTimeout(() => {
            // Get the current step element and scroll it into view
            const currentStepElement =
                this._document.getElementsByClassName('current-step')[0];
            if (currentStepElement) {
                currentStepElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        });
    }
}
