import { inject } from '@angular/core';
import { HashMap } from '@ngneat/transloco';
import { Utilisateur } from 'app/models/Utilisateur';
import { ArticleService } from 'app/shared/article.service';
import { DataService } from 'app/shared/data.service';
import { UtilisateurService } from 'app/shared/utilisateur.service';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';

export const mediaResolver = () => {
  const utilisateurService = inject(UtilisateurService);
  const dataService = inject(DataService);
  const articleService = inject(ArticleService);

  return forkJoin({
    listeArticles: articleService
      .getArticlesLimit()
      .pipe(catchError(() => of([]))),
    listeNews: articleService.getNewsLimit().pipe(catchError(() => of([]))),
    listeArticlesNouvellesDirigeants: articleService
      .getArticleNouvellesDirigeants()
      .pipe(catchError(() => of([]))),
    listeArticlesInfographie: articleService
      .getInfographie()
      .pipe(catchError(() => of([]))),
    listeArticlesEducatifsDebutant: articleService
      .getArticleEducatifsDebutant()
      .pipe(catchError(() => of([]))),
    tunindex: dataService
      .recupererParISIN('TN0009050014')
      .pipe(catchError(() => of())),
    tunindex20: dataService
      .recupererParISIN('TN0009050287')
      .pipe(catchError(() => of())),
  }).pipe(
    switchMap((media) => {
      const auteursIds: string[] = [];
      media.listeArticles.forEach(
        (article) =>
          article.auteur &&
          !auteursIds.includes(article.auteur) &&
          auteursIds.push(article.auteur),
      );
      media.listeArticlesEducatifsDebutant.forEach(
        (article) =>
          article.auteur &&
          !auteursIds.includes(article.auteur) &&
          auteursIds.push(article.auteur),
      );
      return forkJoin(
        auteursIds.reduce(
          (acc, articleId) => {
            acc[articleId] = utilisateurService
              .getUtilisateur(articleId)
              .pipe(catchError(() => of()));
            return acc;
          },
          {} as HashMap<Observable<Utilisateur>>,
        ),
      ).pipe(map((auteurs) => ({ ...media, auteurs })));
    }),
  );
};
