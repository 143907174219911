import {
    CommonModule,
    isPlatformBrowser,
    NgOptimizedImage,
} from '@angular/common';
import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { videosBourseMag } from 'app/shared/BourceMag';
import {
    CarouselComponent,
    CarouselModule,
    OwlOptions,
} from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-podcast-carousel',
    standalone: true,
    imports: [
        NgOptimizedImage,
        MatIconModule,
        CarouselModule,
        CommonModule,
        RouterModule,
    ],
    templateUrl: './podcast-carousel.component.html',
})
export class PodcastCarouselComponent {
    @ViewChild('carousel', { static: false }) carousel!: CarouselComponent;
    isBrowser: boolean = false;
    slides = videosBourseMag;
    options: OwlOptions = {
        loop: true,
        mouseDrag: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            640: {
                items: 2,
            },
            1024: {
                items: 3,
            },
            1280: {
                items: 4,
            },
            1536: {
                items: 5,
            },
        },
    };

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    ngOnInit(): void {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    goToNextSlide() {
        this.carousel.next();
    }

    goToPreviousSlide() {
        this.carousel.prev();
    }
}
