<div *ngIf="isBrowser" class="flex w-full items-center">
    <mat-icon
        class="cursor-pointer text-[#0045E6] icon-size-10"
        svgIcon="feather:chevron-left"
        (click)="goToPreviousSlide()"
    />
    <owl-carousel-o
        #carousel
        class="flex-1 overflow-hidden"
        [options]="options"
    >
        <ng-container>
            <ng-template carouselSlide>
                <div class="flex items-center justify-center">
                    <a a routerLink="/emission/podcast">
                    <img
                        ngSrc="/assets/irbe7/Podcast.webp"
                        class="my-6 h-64 w-64 rounded-xl"
                        alt="publicité"
                        height="256"
                        width="256"
                    />
                </a>
                </div>
            </ng-template>
            <ng-template carouselSlide *ngFor="let slide of slides; index as i">
                <div class="flex items-center justify-center">
                    <a
                        [routerLink]="['/emission', slide.nom, slide.id]"
                        class="flex max-w-80 justify-center py-6 text-sm"
                    >
                        <div class="flex h-64 flex-col justify-center gap-2">
                            <img
                                src="https://i.ytimg.com/vi/{{
                                    slide.id
                                }}/mqdefault.jpg"
                                class="rounded-xl"
                                [alt]="'podcast' + (i + 1)"
                                height="100"
                                width="200"
                            />
                            <h3 class="line-clamp-1 text-lg font-bold">
                                {{ slide.nom }}
                            </h3>
                        </div>
                    </a>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
    <mat-icon
        class="cursor-pointer text-[#0045E6] icon-size-10"
        svgIcon="feather:chevron-right"
        (click)="goToNextSlide()"
    />
</div>
