<div
  class="flex flex-col border-l-2 pl-1 text-start"
  [ngClass]="{
    'border-l-[#0E9687]': change >= 0,
    'border-l-[#E70013]': change < 0,
  }"
>
  <span class="text-sm font-extrabold text-[#7a7a7a]">{{stockName}}</span>
  <span class="font-bold text-xl">{{ last | number: ".2-2" }}</span>
  <div class="flex items-center">
    <mat-icon
      class="icon-size-3"
      [ngClass]="{
        'text-[#0E9687]': change >= 0,
        'text-[#E70013]': change < 0,
      }"
      [svgIcon]="
        change >= 0
          ? 'heroicons_solid:arrow-small-up'
          : 'heroicons_solid:arrow-small-down'
      "
    />
    <span
    class="text-sm"
      [ngClass]="{
        'text-[#0E9687]': change >= 0,
        'text-[#E70013]': change < 0,
      }"
    >
      {{ change | abs | number: ".2-2" }}%
    </span>
  </div>
</div>
