<div class="relative">
    <ng-container *ngIf="isLogged; else notLogged">
        <button (click)="showPopoverMenu = true">
            <span>
                <img
                    *ngIf="showAvatar && username.urlPhotoProfil"
                    class="h-7 w-7 rounded-full"
                    [src]="cloud_front + utilisateur?.urlPhotoProfil"
                />
                <div
                    *ngIf="!showAvatar || !username.urlPhotoProfil"
                    class="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-lg uppercase text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                >
                    {{ username.charAt(0) }}
                </div>
            </span>
        </button>
        <div
            class="absolute right-0 top-9 z-50 flex w-48 flex-col bg-white font-medium shadow-xl"
            *ngIf="showPopoverMenu"
        >
            <button
                class="flex items-center gap-2 px-2 py-3 hover:bg-gray-100"
                (click)="profil()"
            >
                <mat-icon svgIcon="heroicons_outline:user-circle" />
                <span>Profil</span>
            </button>
            <button
                class="flex items-center gap-2 border-t px-2 py-3 hover:bg-gray-100"
                (click)="parametres()"
            >
                <mat-icon svgIcon="heroicons_outline:cog-8-tooth" />
                <span>Paramétres</span>
            </button>
            <button
                class="flex items-center gap-2 border-t px-2 py-3 hover:bg-gray-100"
                (click)="signOut()"
            >
                <mat-icon
                    svgIcon="heroicons_outline:arrow-right-on-rectangle"
                />
                <span>Se déconnecter</span>
            </button>
        </div>
        <div
            *ngIf="showPopoverMenu"
            (click)="showPopoverMenu = false"
            class="fixed inset-0"
        ></div>
    </ng-container>
    <ng-template #notLogged>
        <button
            class="rounded-xl border border-[#0045E6] bg-white px-4 py-2 font-medium text-[#0045E6] text-nowrap"
            (click)="signin()"
        >
            Se connecter
        </button>
    </ng-template>
</div>
