import { CommonModule } from '@angular/common';
import { Component, input, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { AbsPipe } from 'app/shared/abs.pipe';

@Component({
  selector: 'app-index-card',
  standalone: true,
  imports: [CommonModule, MatIcon, AbsPipe],
  templateUrl: './index-card.component.html',
})
export class IndexCardComponent {
  @Input() stockName: string;
  @Input() change: number;
  @Input() last: number;
}
