<div *ngIf="isBrowser" class="flex w-full items-center">
    <mat-icon
        class="cursor-pointer text-[#0045E6] icon-size-10"
        svgIcon="feather:chevron-left"
        (click)="goToPreviousSlide()"
    />
    <owl-carousel-o
        #carousel
        class="flex-1 overflow-hidden"
        [options]="options"
    >
        <ng-container
            *ngFor="let article of listeArticlesInfographie; index as i"
        >
            <ng-template carouselSlide>
                <div class="flex justify-center py-6 text-sm">
                    <a
                        class="flex max-w-60 flex-col gap-1 text-center"
                    [routerLink]="[
                    '/actualites/articles',
                    article?.nom | convertToSlug,
                    article?.id,
                ]"
            >
                        <img
                            [alt]="infographie + (i + 1)"
                            [ngSrc]="cloud_front + article?.image"
                            class="w-full object-cover aspect-square"
                            height="240"
                            width="240"
                        />
                        <h3 class="text-lg font-bold line-clamp-2">{{ article.nom }}</h3>
                        <p class="line-clamp-4">
                            {{ article.description }}
                        </p>
                        <span>{{ article.date | date: 'dd.MM.YYYY' }}</span>
                    </a>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
    <mat-icon
        class="cursor-pointer text-[#0045E6] icon-size-10"
        svgIcon="feather:chevron-right"
        (click)="goToNextSlide()"
    />
</div>
