import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { LoginService } from 'app/login.service';
import { Utilisateur } from 'app/models/Utilisateur';
import { PopupLoginComponent } from 'app/modules/popup-login/popup-login.component';
import { PopupSignUpComponent } from 'app/modules/popup-signup/popup-signup.component';
import { UtilisateurService } from 'app/shared/utilisateur.service';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [CommonModule, MatIconModule],
})
export class UserComponent implements OnInit, OnDestroy {
    static ngAcceptInputType_showAvatar: BooleanInput;
    @Input() showAvatar: boolean = true;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    cloud_front = environment.cloud_front_host;
    utilisateur: Utilisateur;
    username: string;
    isLogged?: boolean;
    idUtilisateurConnecte: string;
    showPopoverMenu: boolean = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private dialog: MatDialog,
        private userService: UserService,
        private utilisateurService: UtilisateurService,
        private loginService: LoginService
    ) {
        this.isLogged = this.loginService.getIsLogged();
    }

    ngOnInit(): void {
        this.username = this.loginService.getUsername();

        if (this.username)
            this.utilisateurService
                .getUtilisateurByUsername(this.username)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe({
                    next: (data: Utilisateur[]) => {
                        this.utilisateur = data[0];
                        this.idUtilisateurConnecte = this.utilisateur?.userId;
                        this.changeDetectorRef.detectChanges();
                    },
                });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    updateUserStatus(status: string): void {
        if (!this.user) {
            return;
        }

        this.userService
            .update({
                ...this.user,
                status,
            })
            .subscribe();
    }

    signin(): void {
        this.dialog.open(PopupLoginComponent);
    }

    signUp(): void {
        this.dialog.open(PopupSignUpComponent);
    }

    signOut(): void {
        this.loginService.logout();
        this.showPopoverMenu = false;
    }

    parametres() {
        this.router.navigate(['/parametre']);
        this.showPopoverMenu = false;
    }
    profil() {
        this.router.navigate(['/mur-utilisateur-connecte']);
        this.showPopoverMenu = false;
    }
}
